import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import { setLoading } from '../loadingsErrors/actions';
import {
  CreateAPIKeyRequest,
  CreateStoreRequest, CreateWithdrawalRequest,
  DeleteAPIKeyRequest, DeleteWithdrawalRequest,
  GetAPIKeysRequest,
  GetStoreInfoRequest, GetWithdrawalsRequest,
  ShowAPIKeyRequest,
  UpdateStoreInfoRequest,
  CreateWebhookRequest,
  DeleteWebhookRequest,
  UpdateStoreRequest,
  GetStoreRequest, GetIPWLRequest, UpdateIPWLRequest,
} from './actionTypes';
import types from '../actionTypes';
import {APIKey, Webhook, WithdrawalRequisite} from "../../api";

// calls
const createStoreCall = (payload?: any) => API.call('createStore', payload);
const getStoresCall = () => API.call('getStores', undefined);
const createApiKeyCall = (payload?: any) => API.call('createAPIKey', payload);
const getApiKeysCall = (payload?: any) => API.call('getAPIKeys', payload);
const deleteApiKeyCall = (payload?: any) => API.call('deleteAPIKey', payload);
const showApiKeyCall = (payload?: any) => API.call('showAPIKey', payload);
const createWebhookCall = (payload?: any) => API.call('createWebhook', payload);
const deleteWebhookCall = (payload?: any) => API.call('deleteWebhook', payload);
const updateStoreInfoCall = (payload?: any) => API.call('updateStoreInfo', payload);
const getStoreInfoCall = (payload?: any) => API.call('getStoreInfo', payload);
const createWithdrawalRequisiteCall = (payload?: any) => API.call('createWithdrawalRequisites', payload);
const getWithdrawalRequisitesCall = (payload?: any) => API.call('getWithdrawalsRequisites', payload);
const deleteWithdrawalRequisiteCall = (payload?: any) => API.call('deleteWithdrawalRequisites', payload);
const updateStoreCall = (payload?: any) => API.call('updateStore', payload);
const getStoreCall = (payload?: any) => API.call('getStore', payload);
const getIPWLCall = (payload?: any) => API.call('getIPWL', payload);
const updateIPWLCall = (payload?: any) => API.call('updateIPWL', payload);

// call types
type CreateStore = SagaReturnType<typeof createStoreCall>;
type GetStores = SagaReturnType<typeof getStoresCall>;
type GetAPIKeys = SagaReturnType<typeof getApiKeysCall>;
type ShowAPIKeys = SagaReturnType<typeof showApiKeyCall>;
type GetStoreInfo = SagaReturnType<typeof getStoreInfoCall>;
type GetWithdrawalRequisites = SagaReturnType<typeof getWithdrawalRequisitesCall>;
type GetStore = SagaReturnType<typeof getStoreCall>;
type GetIPWL = SagaReturnType<typeof getApiKeysCall>;

function* createStore({ payload }: CreateStoreRequest) {
  yield put(setLoading(types.CREATE_STORE_REQUEST, true));
  try {
    const store: CreateStore = yield call(() => createStoreCall(payload));
    yield all([
      put({
        type: types.CREATE_STORE_SUCCESS,
        payload: {
          store,
          answer: {
            success: 'You have successfully create shop'
          }
        }
      }),
      put(setLoading(types.CREATE_STORE_REQUEST, false))
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_STORE_REQUEST, false)),
        put({
          type: types.CREATE_STORE_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* getStores() {
  yield put(setLoading(types.GET_STORES_REQUEST, true));
  try {
    const res: GetStores = yield call(() => getStoresCall());

    yield all([
      put({ type: types.GET_STORES_SUCCESS, payload: res }),
      put(setLoading(types.GET_STORES_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};
    yield all([
      put(setLoading(types.GET_STORES_REQUEST, false)),
      put({
        type: types.GET_STORES_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* createApiKey({ payload }: CreateAPIKeyRequest) {
  yield put(setLoading(types.CREATE_API_KEY_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: APIKey = yield call(() => createApiKeyCall(payload));

    yield all([
      put({
        type: types.CREATE_API_KEY_SUCCESS,
        payload: {
          storeId,
          apiKey: [res],
          answer: {
            success: 'You API key was successfully created'
          }
        }
      }),
      put(setLoading(types.CREATE_API_KEY_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_API_KEY_REQUEST, false)),
        put({
          type: types.CREATE_API_KEY_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* getApiKeys({ payload }: GetAPIKeysRequest) {
  yield put(setLoading(types.GET_API_KEYS_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetAPIKeys = yield call(() => getApiKeysCall(payload));
    yield all([
      put({
        type: types.GET_API_KEYS_SUCCESS,
        payload: {
          storeId,
          apiKeys: res
        }
      }),
      put(setLoading(types.GET_API_KEYS_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_API_KEYS_REQUEST, false)),
      put({
        type: types.GET_API_KEYS_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* deleteApiKey({ payload }: DeleteAPIKeyRequest) {
  yield put(setLoading(types.DELETE_API_KEY_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const apiKeyId: string = payload?.apiKeyId || '';
  try {
    yield call(() => deleteApiKeyCall(payload));

    yield all([
      put({
        type: types.DELETE_API_KEY_SUCCESS,
        payload: {
          storeId,
          apiKeyId,
          answer: {
            success: 'You API key was successfully revoked'
          }
        }
      }),
      put(setLoading(types.DELETE_API_KEY_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.DELETE_API_KEY_REQUEST, false)),
      put({
        type: types.DELETE_API_KEY_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* showApiKey({ payload }: ShowAPIKeyRequest) {
  yield put(setLoading(types.SHOW_API_KEY_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const apiKeyId: string = payload?.apiKeyId || '';
  try {
    const res: ShowAPIKeys = yield call(() => showApiKeyCall(payload));

    yield all([
      put({
        type: types.SHOW_API_KEY_SUCCESS,
        payload: {
          storeId,
          apiKeyId,
          apiKey: res.key,
        }
      }),
      put(setLoading(types.SHOW_API_KEY_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.SHOW_API_KEY_REQUEST, false)),
      put({
        type: types.SHOW_API_KEY_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* createWebhook({ payload }: CreateWebhookRequest) {
  yield put(setLoading(types.CREATE_WEBHOOK_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const webhookUrl: Webhook = payload?.data.webhook_url || '';
  try {
    yield call(() => createWebhookCall(payload));
    yield all([
      put({
        type: types.CREATE_WEBHOOK_SUCCESS,
        payload: {
          storeId,
          webhookUrl,
          answer: {
            success: 'You endpoint was successfully created'
          }
        }
      }),
      put(setLoading(types.CREATE_WEBHOOK_REQUEST, false)),
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_WEBHOOK_REQUEST, false)),
        put({
          type: types.CREATE_WEBHOOK_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* deleteWebhook({ payload }: DeleteWebhookRequest) {
  yield put(setLoading(types.DELETE_WEBHOOK_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const webhookUrl: Webhook = payload?.data.webhook_url || '';
  try {
    yield call(() => deleteWebhookCall(payload));

    yield all([
      put({
        type: types.DELETE_WEBHOOK_SUCCESS,
        payload: {
          storeId,
          webhookUrl,
          answer: {
            success: 'You webhook was successfully removed'
          }
        }
      }),
      put(setLoading(types.DELETE_WEBHOOK_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.DELETE_WEBHOOK_REQUEST, false)),
      put({
        type: types.DELETE_WEBHOOK_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateStoreInfo({ payload }: UpdateStoreInfoRequest) {
  yield put(setLoading(types.UPDATE_STORE_INFO_REQUEST, true));
  try {
    yield call(() => updateStoreInfoCall(payload));
    const res: GetStore = yield call(() => getStoreCall({storeId: payload.storeId}));

    yield all([
      put({
        type: types.UPDATE_STORE_INFO_SUCCESS,
        payload: {
          store: res,
          answer: {
            success: 'Your store details was successfully updated'
          }
        }
      }),
      put(setLoading(types.UPDATE_STORE_INFO_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_STORE_INFO_REQUEST, false)),
      put({
        type: types.UPDATE_STORE_INFO_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getStoreInfo({ payload }: GetStoreInfoRequest) {
  yield put(setLoading(types.GET_STORE_INFO_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetStoreInfo = yield call(() => getStoreInfoCall(payload));

    yield all([
      put({
        type: types.GET_STORE_INFO_SUCCESS,
        payload: {
          storeId,
          data: res,
        }
      }),
      put(setLoading(types.GET_STORE_INFO_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_STORE_INFO_REQUEST, false)),
      put({
        type: types.GET_STORE_INFO_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* createWithdrawal({ payload }: CreateWithdrawalRequest) {
  yield put(setLoading(types.CREATE_WITHDRAWAL_REQUISITES_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: WithdrawalRequisite = yield call(() => createWithdrawalRequisiteCall(payload));

    if (res.error) {
      yield all([
        put(setLoading(types.CREATE_WITHDRAWAL_REQUISITES_REQUEST, false)),
        put({
          type: types.CREATE_WITHDRAWAL_REQUISITES_FAILURE,
          payload: {error: res?.error.toString()},
        }),
      ]);
    } else {
      yield all([
        put({
          type: types.CREATE_WITHDRAWAL_REQUISITES_SUCCESS,
          payload: {
            storeId,
            withdrawal: [res],
            answer: {
              success: 'You Withdrawal requisite was successfully created'
            }
          }
        }),
        put(setLoading(types.CREATE_WITHDRAWAL_REQUISITES_REQUEST, false)),
      ]);
    }

  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CREATE_WITHDRAWAL_REQUISITES_REQUEST, false)),
        put({
          type: types.CREATE_WITHDRAWAL_REQUISITES_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* getWithdrawals({ payload }: GetWithdrawalsRequest) {
  yield put(setLoading(types.GET_WITHDRAWALS_REQUISITES_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetWithdrawalRequisites = yield call(() => getWithdrawalRequisitesCall(payload));
    yield all([
      put({
        type: types.GET_WITHDRAWALS_REQUISITES_SUCCESS,
        payload: {
          storeId,
          withdrawals: res
        }
      }),
      put(setLoading(types.GET_WITHDRAWALS_REQUISITES_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_WITHDRAWALS_REQUISITES_REQUEST, false)),
      put({
        type: types.GET_WITHDRAWALS_REQUISITES_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* deleteWithdrawal({ payload }: DeleteWithdrawalRequest) {
  yield put(setLoading(types.DELETE_WITHDRAWAL_REQUISITES_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const withdrawalId: string = payload?.withdrawalId || '';
  try {
    yield call(() => deleteWithdrawalRequisiteCall(payload));

    yield all([
      put({
        type: types.DELETE_WITHDRAWAL_REQUISITES_SUCCESS,
        payload: {
          storeId,
          withdrawalId,
          answer: {
            success: 'You Withdrawal requisite was successfully revoked'
          }
        }
      }),
      put(setLoading(types.DELETE_WITHDRAWAL_REQUISITES_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.DELETE_WITHDRAWAL_REQUISITES_REQUEST, false)),
      put({
        type: types.DELETE_WITHDRAWAL_REQUISITES_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateStore({ payload }: UpdateStoreRequest) {
  yield put(setLoading(types.UPDATE_STORE_REQUEST, true));
  try {
    const storeId = payload?.storeId || '';
    const storeParams = payload?.params || {};
    yield call(() => updateStoreCall(payload));

    yield all([
      put({
        type: types.UPDATE_STORE_SUCCESS,
        payload: {
          storeId,
          storeParams,
          answer: {
            success: 'Your store was successfully updated'
          }
        }
      }),
      put(setLoading(types.UPDATE_STORE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_STORE_REQUEST, false)),
      put({
        type: types.UPDATE_STORE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getStore({ payload }: GetStoreRequest) {
  yield put(setLoading(types.GET_STORE_REQUEST, true));
  try {
    const storeId = payload?.storeId || '';
    const res: GetStore = yield call(() => getStoreCall(payload));

    yield all([
      put({
        type: types.GET_STORE_SUCCESS,
        payload: {
          storeId,
          store: res,
        }
      }),
      put(setLoading(types.GET_STORE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_STORE_REQUEST, false)),
      put({
        type: types.GET_STORE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getIPWL({ payload }: GetIPWLRequest) {
  yield put(setLoading(types.GET_IPWL_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  try {
    const res: GetIPWL = yield call(() => getIPWLCall(payload));
    yield all([
      put({
        type: types.GET_IPWL_SUCCESS,
        payload: {
          storeId,
          ipwl: res
        }
      }),
      put(setLoading(types.GET_IPWL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_IPWL_REQUEST, false)),
      put({
        type: types.GET_IPWL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateIPWL({ payload }: UpdateIPWLRequest) {
  yield put(setLoading(types.UPDATE_IPWL_REQUEST, true));
  const storeId: string = payload?.storeId || '';
  const ipwls: string = payload?.data.address_whitelist || '';
  try {
    yield call(() => updateIPWLCall(payload));
    yield all([
      put({
        type: types.UPDATE_IPWL_SUCCESS,
        payload: {
          storeId,
          ipwl: ipwls.split(','),
          answer: {
            success: 'New IP address was successfully added'
          }
        }
      }),
      put(setLoading(types.UPDATE_IPWL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_IPWL_REQUEST, false)),
      put({
        type: types.UPDATE_IPWL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

export default function* storesSagas() {
  yield takeLatest(types.CREATE_STORE_REQUEST, createStore);
  yield takeLatest(types.GET_STORES_REQUEST, getStores);
  yield takeLatest(types.CREATE_API_KEY_REQUEST, createApiKey);
  yield takeLatest(types.GET_API_KEYS_REQUEST, getApiKeys);
  yield takeLatest(types.DELETE_API_KEY_REQUEST, deleteApiKey);
  yield takeLatest(types.SHOW_API_KEY_REQUEST, showApiKey);
  yield takeLatest(types.CREATE_WEBHOOK_REQUEST, createWebhook);
  yield takeLatest(types.DELETE_WEBHOOK_REQUEST, deleteWebhook);
  yield takeLatest(types.UPDATE_STORE_INFO_REQUEST, updateStoreInfo);
  yield takeLatest(types.GET_STORE_INFO_REQUEST, getStoreInfo);
  yield takeLatest(types.CREATE_WITHDRAWAL_REQUISITES_REQUEST, createWithdrawal);
  yield takeLatest(types.GET_WITHDRAWALS_REQUISITES_REQUEST, getWithdrawals);
  yield takeLatest(types.DELETE_WITHDRAWAL_REQUISITES_REQUEST, deleteWithdrawal);
  yield takeLatest(types.UPDATE_STORE_REQUEST, updateStore);
  yield takeLatest(types.GET_STORE_REQUEST, getStore);
  yield takeLatest(types.GET_IPWL_REQUEST, getIPWL);
  yield takeLatest(types.UPDATE_IPWL_REQUEST, updateIPWL);
}
