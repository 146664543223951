import {APIKey, IPWL, Store, WithdrawalRequisite} from '../../api';
import types, { AppActionTypes } from '../actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import pull from 'lodash/pull';
import findIndex from 'lodash/findIndex';
import {LOADING_TYPES} from "../../const/app.constants";
import {STORAGE_KEYS} from "../../const/storage_keys.constants";

export type StoresReducerState = {
  stores: {
    list: Store[];
    loaded: LOADING_TYPES;
    created: boolean;
    changed: boolean;
    loadedShop: LOADING_TYPES;
  };
  apiKeys: { [key: string]: APIKey[] };
  createdApiKey: APIKey | undefined,
  activeStore: string | null;
  answer: any;
  useTestnet: boolean;
  ip_wls: { [key: string]: IPWL[] };
};

const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
const active_store = localStorage.getItem(useTestnet ? STORAGE_KEYS.ACTIVE_TESTNET_STORE : STORAGE_KEYS.ACTIVE_STORE) || null;

const INITIAL_STATE: StoresReducerState = {
  stores: {
    list: [],
    loaded: LOADING_TYPES.NOT_LOADED,
    created: false,
    changed: false,
    loadedShop: LOADING_TYPES.NOT_LOADED,

  },
  apiKeys: {},
  createdApiKey: undefined,
  activeStore: active_store,
  answer: {},
  useTestnet,
  ip_wls: {}
};

const storesReducers = (state = INITIAL_STATE, action: AppActionTypes): StoresReducerState => {
  switch (action.type) {
    case types.CREATE_STORE_REQUEST:
      const createStoreRequest = cloneDeep(state.stores);
      return {
        ...state,
        stores: createStoreRequest,
        answer: {},
      };
    case types.CREATE_STORE_SUCCESS:
      const createStoreSuccess = cloneDeep(state.stores);
      createStoreSuccess.list.push(action.payload.store);
      createStoreSuccess.created = true;
      localStorage.setItem(state.useTestnet ? STORAGE_KEYS.ACTIVE_TESTNET_STORE : STORAGE_KEYS.ACTIVE_STORE, action.payload.store.id);

      return {
        ...state,
        stores: createStoreSuccess,
        activeStore: action.payload.store.id,
        answer: action.payload.answer || {},
      };
    case types.CREATE_STORE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_STORES_REQUEST:
      const getStoresRequest = cloneDeep(state.stores);
      getStoresRequest.loaded = LOADING_TYPES.LOADING;

      return {
        ...state,
        stores: getStoresRequest,
        answer: {},
      };
    case types.GET_STORES_SUCCESS:
      const getStoresSuccess = cloneDeep(state.stores);
      const newStoresList = action.payload;

      getStoresSuccess.list = newStoresList || getStoresSuccess.list;
      getStoresSuccess.list = getStoresSuccess.list.map((store: Store) => {
        return {
          id: store.id,
          name: store.name,
          balance: store.balance,
          loadedInfo: LOADING_TYPES.NOT_LOADED,
          loadedWithdrawals: LOADING_TYPES.NOT_LOADED,
          webhook_urls: [],
          withdrawals: [],
          ip_wls: [],
        }
      });
      getStoresSuccess.loaded = LOADING_TYPES.LOADED;
      getStoresSuccess.loadedShop = LOADING_TYPES.NOT_LOADED;

      // const lastStore = getStoresSuccess.list.length ? getStoresSuccess.list[getStoresSuccess.list.length - 1].id : null;
      const firstStore = getStoresSuccess.list.length ? getStoresSuccess.list[0].id : null;
      let newActiveStore = state.activeStore ? state.activeStore : null;

      if (!newActiveStore) {
        newActiveStore = firstStore;
      }

      return {
        ...state,
        stores: getStoresSuccess,
        activeStore: newActiveStore
      };
    case types.GET_STORES_FAILURE:
      const getStoresFailure = cloneDeep(state.stores);
      getStoresFailure.loaded = LOADING_TYPES.LOADED;

      return {
        ...state,
        stores: getStoresFailure,
        answer: action.payload || {},
      };
    case types.SET_STORE:
      const setStoreStores = cloneDeep(state.stores);
      const settedStore = action.payload;
      if (settedStore) {
        localStorage.setItem(state.useTestnet ? STORAGE_KEYS.ACTIVE_TESTNET_STORE : STORAGE_KEYS.ACTIVE_STORE, settedStore);
        setStoreStores.changed = false;
      }
      setStoreStores.created = false;

      return {
        ...state,
        stores: setStoreStores,
        activeStore: settedStore
      };
    case types.CREATE_API_KEY_REQUEST:
      return {
        ...state,
        createdApiKey: undefined,
        answer: {},
      };
    case types.CREATE_API_KEY_SUCCESS:
      const createAPIKeyStoresSuccess = cloneDeep(state.stores);
      const createAPIKeySuccess = cloneDeep(state.apiKeys);
      const createAPIKeySuccessIndex: number = findIndex(createAPIKeyStoresSuccess.list, { id: action.payload.storeId});
      createAPIKeySuccess[action.payload.storeId] = [...createAPIKeySuccess[action.payload.storeId], ...action.payload.apiKey];
      createAPIKeyStoresSuccess.list = createAPIKeyStoresSuccess.list.map((store: Store) => {
        store.loadedInfo = LOADING_TYPES.NOT_LOADED;
        store.loadedWithdrawals = LOADING_TYPES.NOT_LOADED;
        return store
      });
      createAPIKeyStoresSuccess.loaded = LOADING_TYPES.LOADED;
      createAPIKeyStoresSuccess.list[createAPIKeySuccessIndex].has_api_key = true;

      return {
        ...state,
        apiKeys: createAPIKeySuccess,
        createdApiKey: action.payload.apiKey[0],
        stores: createAPIKeyStoresSuccess,
        answer: action.payload.answer || {},
      };
    case types.CREATE_API_KEY_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_API_KEYS_REQUEST:
      const getAPIKeyRequest = cloneDeep(state.apiKeys);
      getAPIKeyRequest[action.payload.storeId] = [];

      return {
        ...state,
        apiKeys: getAPIKeyRequest,
        answer: {},
      };
    case types.GET_API_KEYS_SUCCESS:
      const getAPIKeySuccess = cloneDeep(state.apiKeys);
      getAPIKeySuccess[action.payload.storeId] = [...getAPIKeySuccess[action.payload.storeId], ...action.payload.apiKeys];
      return {
        ...state,
        apiKeys: getAPIKeySuccess,
      };
    case types.GET_API_KEYS_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.DELETE_API_KEY_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.DELETE_API_KEY_SUCCESS:
      const deleteAPIKeySuccess = cloneDeep(state.apiKeys);
      remove(deleteAPIKeySuccess[action.payload.storeId], { id: action.payload.apiKeyId});

      return {
        ...state,
        apiKeys: deleteAPIKeySuccess,
        answer: action.payload.answer || {},
      };
    case types.DELETE_API_KEY_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.SHOW_API_KEY_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.SHOW_API_KEY_SUCCESS:
      const showAPIKeySuccess = cloneDeep(state.apiKeys);
      const showedApiKeyIndex: number = findIndex(showAPIKeySuccess[action.payload.storeId], { id: action.payload.apiKeyId});
      showAPIKeySuccess[action.payload.storeId][showedApiKeyIndex].key = action.payload.apiKey;
      return {
        ...state,
        apiKeys: showAPIKeySuccess,
      };
    case types.SHOW_API_KEY_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CREATE_WEBHOOK_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.CREATE_WEBHOOK_SUCCESS:
      const createWebhookSuccess = cloneDeep(state.stores);
      const createWebhookSuccessIndex: number = findIndex(createWebhookSuccess.list, { id: action.payload.storeId});
      if (createWebhookSuccess.list[createWebhookSuccessIndex].webhook_urls) {
        createWebhookSuccess.list[createWebhookSuccessIndex].webhook_urls = [...createWebhookSuccess.list[createWebhookSuccessIndex].webhook_urls, action.payload.webhookUrl];
      } else {
        createWebhookSuccess.list[createWebhookSuccessIndex].webhook_urls = [action.payload.webhookUrl];
      }
      return {
        ...state,
        stores: createWebhookSuccess,
        answer: action.payload.answer || {},
      };
    case types.CREATE_WEBHOOK_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.DELETE_WEBHOOK_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.DELETE_WEBHOOK_SUCCESS:
      const deleteWebhookSuccess = cloneDeep(state.stores);
      const deleteWebhookSuccessIndex: number = findIndex(deleteWebhookSuccess.list, { id: action.payload.storeId});
      pull(deleteWebhookSuccess.list[deleteWebhookSuccessIndex].webhook_urls, action.payload.webhookUrl);

      return {
        ...state,
        stores: deleteWebhookSuccess,
        answer: action.payload.answer || {},
      };
    case types.DELETE_WEBHOOK_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_STORE_INFO_REQUEST:
      const updateStoreInfoRequest = cloneDeep(state.stores);
      const updateStoreInfoIndex: number = findIndex(updateStoreInfoRequest.list, { id: action.payload.storeId});
      updateStoreInfoRequest.list[updateStoreInfoIndex].info = action.payload.data;

      return {
        ...state,
        answer: {},
      };
    case types.UPDATE_STORE_INFO_SUCCESS:
      const updateStoreInfoSuccess = cloneDeep(state.stores);
      const updateStoreInfoSuccessIndex: number = findIndex(updateStoreInfoSuccess.list, { id: action.payload.store.id});

      updateStoreInfoSuccess.list[updateStoreInfoSuccessIndex] = action.payload.store;
      updateStoreInfoSuccess.list[updateStoreInfoSuccessIndex].loadedInfo = LOADING_TYPES.NOT_LOADED;
      updateStoreInfoSuccess.list[updateStoreInfoSuccessIndex].loadedWithdrawals = LOADING_TYPES.NOT_LOADED;

      return {
        ...state,
        stores: updateStoreInfoSuccess,
        answer: action.payload.answer || {},
      };
    case types.UPDATE_STORE_INFO_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_STORE_INFO_REQUEST:
      const getStoreInfoRequest = cloneDeep(state.stores);
      const getStoreInfoIndex: number = findIndex(getStoreInfoRequest.list, { id: action.payload.storeId});
      getStoreInfoRequest.list[getStoreInfoIndex].loadedInfo = LOADING_TYPES.LOADING;

      return {
        ...state,
        stores: getStoreInfoRequest,
        answer: {},
      };
    case types.GET_STORE_INFO_SUCCESS:
      const getStoreInfoSuccess = cloneDeep(state.stores);
      const storeInfoIndex: number = findIndex(getStoreInfoSuccess.list, { id: action.payload.storeId});
      getStoreInfoSuccess.list[storeInfoIndex].info = action.payload.data;
      getStoreInfoSuccess.list[storeInfoIndex].loadedInfo = LOADING_TYPES.LOADED;

      return {
        ...state,
        stores: getStoreInfoSuccess,
      };
    case types.GET_STORE_INFO_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CREATE_WITHDRAWAL_REQUISITES_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.CREATE_WITHDRAWAL_REQUISITES_SUCCESS:
      const createWithdrawalSuccess = cloneDeep(state.stores);
      const createWithdrawalIndex: number = findIndex(createWithdrawalSuccess.list, { id: action.payload.storeId});
      const oldWithdrawals: WithdrawalRequisite[] = createWithdrawalSuccess.list[createWithdrawalIndex].withdrawals || [];
      createWithdrawalSuccess.list[createWithdrawalIndex].withdrawals = [...oldWithdrawals, ...action.payload.withdrawal];

      return {
        ...state,
        stores: createWithdrawalSuccess,
        answer: action.payload.answer || {},
      };
    case types.CREATE_WITHDRAWAL_REQUISITES_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_WITHDRAWALS_REQUISITES_REQUEST:
      const getWithdrawalsRequest = cloneDeep(state.stores);
      const getWithdrawalsIndex: number = findIndex(getWithdrawalsRequest.list, { id: action.payload.storeId});
      getWithdrawalsRequest.list[getWithdrawalsIndex].loadedWithdrawals = LOADING_TYPES.LOADING;

      return {
        ...state,
        stores: getWithdrawalsRequest,
        answer: {},
      };
    case types.GET_WITHDRAWALS_REQUISITES_SUCCESS:
      const getWithdrawalsSuccess = cloneDeep(state.stores);
      const getWithdrawalIndex: number = findIndex(getWithdrawalsSuccess.list, { id: action.payload.storeId});
      getWithdrawalsSuccess.list[getWithdrawalIndex].withdrawals = action.payload.withdrawals;
      getWithdrawalsSuccess.list[getWithdrawalIndex].loadedWithdrawals = LOADING_TYPES.LOADED;

      return {
        ...state,
        stores: getWithdrawalsSuccess,
      };
    case types.GET_WITHDRAWALS_REQUISITES_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.DELETE_WITHDRAWAL_REQUISITES_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.DELETE_WITHDRAWAL_REQUISITES_SUCCESS:
      const deleteWithdrawalSuccess = cloneDeep(state.stores);
      const deleteWithdrawalIndex: number = findIndex(deleteWithdrawalSuccess.list, { id: action.payload.storeId});
      const oldDeleteWithdrawals: WithdrawalRequisite[] = deleteWithdrawalSuccess.list[deleteWithdrawalIndex].withdrawals || [];
      remove(oldDeleteWithdrawals, { id: action.payload.withdrawalId});

      return {
        ...state,
        stores: deleteWithdrawalSuccess,
        answer: action.payload.answer || {},
      };
    case types.DELETE_WITHDRAWAL_REQUISITES_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_STORE_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.UPDATE_STORE_SUCCESS:
      const updateStoreSuccess = cloneDeep(state.stores);
      const updateStoreIndex: number = findIndex(updateStoreSuccess.list, { id: action.payload.storeId});
      updateStoreSuccess.list[updateStoreIndex].name = action.payload.storeParams.name;
      updateStoreSuccess.list[updateStoreIndex].allowed_amount_diff_percent = action.payload.storeParams.allowed_amount_diff_percent;
      updateStoreSuccess.list[updateStoreIndex].min_btc_confirmations = action.payload.storeParams.min_btc_confirmations;
      updateStoreSuccess.list[updateStoreIndex].min_eth_confirmations = action.payload.storeParams.min_eth_confirmations;
      updateStoreSuccess.list[updateStoreIndex].min_btc_tx_amount = action.payload.storeParams.min_btc_tx_amount;
      updateStoreSuccess.list[updateStoreIndex].min_usdteth_tx_amount = action.payload.storeParams.min_usdteth_tx_amount;
      updateStoreSuccess.list[updateStoreIndex].min_usdceth_tx_amount = action.payload.storeParams.min_usdceth_tx_amount;
      updateStoreSuccess.list[updateStoreIndex].min_daieth_tx_amount = action.payload.storeParams.min_daieth_tx_amount;
      updateStoreSuccess.list[updateStoreIndex].min_usdttrx_tx_amount = action.payload.storeParams.min_usdttrx_tx_amount;

      return {
        ...state,
        stores: updateStoreSuccess,
        answer: action.payload.answer || {},
      };
    case types.UPDATE_STORE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_STORE_REQUEST:
      const getStoreRequest = cloneDeep(state.stores);

      getStoreRequest.loadedShop = LOADING_TYPES.LOADING;
      return {
        ...state,
        stores: getStoreRequest,
        answer: {},
      };
    case types.GET_STORE_SUCCESS:
      const getStoreSuccess = cloneDeep(state.stores);
      const newStore = action.payload.store;
      const getStoreSuccessIndex: number = findIndex(getStoreSuccess.list, { id: action.payload?.storeId});
      getStoreSuccess.list[getStoreSuccessIndex] = newStore;
      getStoreSuccess.list[getStoreSuccessIndex].loadedInfo = LOADING_TYPES.NOT_LOADED;
      getStoreSuccess.list[getStoreSuccessIndex].loadedWithdrawals = LOADING_TYPES.NOT_LOADED;
      getStoreSuccess.list[getStoreSuccessIndex].withdrawals = [];

      getStoreSuccess.loadedShop = LOADING_TYPES.LOADED;

      return {
        ...state,
        stores: getStoreSuccess
      };
    case types.GET_STORE_FAILURE:
      const getStoreFailure = cloneDeep(state.stores);
      getStoreFailure.loadedShop = LOADING_TYPES.NOT_LOADED;
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_IPWL_REQUEST:
      const getIPWLRequest = cloneDeep(state.ip_wls);
      getIPWLRequest[action.payload.storeId] = [];

      return {
        ...state,
        ip_wls: getIPWLRequest,
        answer: {},
      };
    case types.GET_IPWL_SUCCESS:
      const getIPWLSuccess = cloneDeep(state.ip_wls);
      getIPWLSuccess[action.payload.storeId] = action.payload.ipwl;
      return {
        ...state,
        ip_wls: getIPWLSuccess,
      };
    case types.GET_IPWL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_IPWL_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.UPDATE_IPWL_SUCCESS:
      const updateIPWLSuccess = cloneDeep(state.ip_wls);
      updateIPWLSuccess[action.payload.storeId] = action.payload.ipwl;

      return {
        ...state,
        ip_wls: updateIPWLSuccess,
        answer: action.payload.answer || {},
      };
    case types.UPDATE_IPWL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CLEAR:
      return {
        ...state,
        answer: {},
      };
    case types.LOGOUT_SUCCESS:
      return INITIAL_STATE;
    case types.CHANGE_USING_TESTNET_SUCCESS:
      const newUseTestnet = action.payload.testnet;
      const changeUsingTestnetSuccess = cloneDeep(INITIAL_STATE);
      changeUsingTestnetSuccess.stores.changed = true
      changeUsingTestnetSuccess.useTestnet = newUseTestnet

      const new_active_store = localStorage.getItem(newUseTestnet ? STORAGE_KEYS.ACTIVE_TESTNET_STORE : STORAGE_KEYS.ACTIVE_STORE) || null;
      changeUsingTestnetSuccess.activeStore = new_active_store


      return changeUsingTestnetSuccess;
    default:
      return state;
  }
};

export default storesReducers;
