import React, {useCallback, useState, useEffect} from 'react';
import { connect } from 'react-redux';

import {
  Container,
  MainContent,
  Button,
  Loader,
  Input,
} from '../../../elements';
import { OauthPasswordStyles } from './OauthPassword.Styles';
import {ConfirmUpdateEmailCodeParams, EmailData, ForgotPasswordParams, UpdateForgottenParams} from "../../../api";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import {clear, getProfile} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import cloneDeep from "lodash/cloneDeep";
import {validateEmail} from "../../../common/utils/validators";
import API from "../../../api/executor";

export interface ConfirmEmailProps {
  user: UserReducerState;
  getProfile: () => void;
  clear: () => void;
}

export enum RECOVER_STAGES {
  SET_EMAIL = 'SET_EMAIL',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  CONFIRM_CODE = 'CONFIRM_CODE',
  SET_PASSWORD = 'SET_PASSWORD',
  YOUR_EMAIL = 'YOUR_EMAIL',
}

const OauthPassword: React.FC<ConfirmEmailProps> = (props: ConfirmEmailProps) => {
  const { user, getProfile, clear } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const stageParam: RECOVER_STAGES | undefined = RECOVER_STAGES[searchParams.get('stage') || ''];
  const recoveryEmail: string = searchParams.get('email') || ''

  const [values, setValues] = useState<{ [key: string]: string }>({
    new_email: recoveryEmail,
    new_email_confirmation_token: '',
    reset_password_token: '',
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [stage, setStage] = useState<RECOVER_STAGES>(stageParam ? stageParam : user.profile.email?.includes('cryptoprocessing.io') ? RECOVER_STAGES.SET_EMAIL : RECOVER_STAGES.YOUR_EMAIL);
  const [loading, setLoading] = useState<boolean>(false);

  let getFormErrors: (data: { [p: string]: string }) => any;
  getFormErrors = (data: { [key: string]: string }) => {
    const {new_email, new_email_confirmation_token, reset_password_token, password, password_confirmation} = data;
    const newErrors: any = {
      email: '',
      new_email_confirmation_token: '',
      reset_password_token: '',
      password: '',
      password_confirmation: '',
    };

    if (stage === RECOVER_STAGES.SET_EMAIL) {
      if (!new_email) newErrors.email = 'Enter your email';
      if (new_email && !validateEmail(new_email)) newErrors.email = 'Please enter a valid email';
    }

    if (stage === RECOVER_STAGES.CONFIRM_EMAIL) {
      if (!new_email_confirmation_token) newErrors.new_email_confirmation_token = 'Enter new email confirmation code';
    }

    if (stage === RECOVER_STAGES.SET_PASSWORD) {
      if (!reset_password_token) newErrors.reset_password_token = 'Enter change password code';
      if (!password) newErrors.password = 'Enter new password';
      if (!password_confirmation) newErrors.password_confirmation = 'Enter new password again';
      if (password !== password_confirmation) newErrors.password_confirmation = 'Passwords mismatch';

    }

    return newErrors;
  };

  const handleSetEmail = async (newEmail: string) => {
    const emailDataProps: EmailData = {
      email: newEmail,
    };

    const setEmailCall = (payload: EmailData) => API.call('updateEmail', payload);

    await setEmailCall(emailDataProps).then(() => {
      setStage(RECOVER_STAGES.CONFIRM_EMAIL);
      setSearchParams({ stage: RECOVER_STAGES.CONFIRM_EMAIL, email: newEmail });
    }).catch((err: any) => {
      const newErrors: any = {
        email: ''
      }

      Object.keys(err.errObj).forEach((key: string) => {
        newErrors[key] = err.errObj[key];
      });

      setErrors(newErrors);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSetEmailCode = async (confirmationToken: string) => {
    const confirmEmailData: ConfirmUpdateEmailCodeParams = {
      new_email_confirmation_token: confirmationToken,
    };

    const confirmEmailCall = (payload: ConfirmUpdateEmailCodeParams) => API.call('confirmUpdateEmailCode', payload);

    await confirmEmailCall(confirmEmailData).then(() => {
      setStage(RECOVER_STAGES.CONFIRM_CODE);
      setSearchParams({ stage: RECOVER_STAGES.CONFIRM_CODE, email: values.new_email });
      getProfile();
    }).catch((err: any) => {
      const newErrors: any = {
        new_email_confirmation_token: err.errObj.base
      }

      setErrors(newErrors);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSendRecoveryCode = async () => {
    const recoveryCodeData: ForgotPasswordParams = {
      email: values.new_email,
    };

    const recoveryCodeCall = (payload: ForgotPasswordParams) => API.call('forgotPassword', payload);

    await recoveryCodeCall(recoveryCodeData).then(() => {
      toastSuccess('confirmation token has been sent');
      clear()
    }).catch((error: any) => {
      toastError(error.errObj.error);
      clear()
    });
  };

  const handleSetPassword = async () => {
    const confirmData: UpdateForgottenParams = {
      reset_password_token: values.reset_password_token,
      password: values.password,
      password_confirmation: values.password_confirmation,
    };

    const setPasswordCall = (payload: UpdateForgottenParams) => API.call('updateForgottenPassword', payload);

    await setPasswordCall(confirmData).then(() => {
      toastSuccess('you successfully confirm new password');
      setSearchParams({});
      navigate(PATHS.NEW_DASHBOARD);
      clear()
    }).catch((err: any) => {
      toastError(err.errObj.error);
      clear()
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleYourEmail = async () => {
    const yourEmailData: ForgotPasswordParams = {
      email: user.profile?.email?.toLowerCase(),
    };
    setLoading(false);
    const yourEmailCall = (payload: ForgotPasswordParams) => API.call('forgotPassword', payload);

    await yourEmailCall(yourEmailData).then(() => {
      setStage(RECOVER_STAGES.SET_PASSWORD);
      setSearchParams({ stage: RECOVER_STAGES.SET_PASSWORD });
      toastSuccess('confirmation token has been sent');
      clear()
    }).catch((error: any) => {
      toastError(error.errObj.error);
      clear()
    });
  };

  const onSubmitNewEmail = useCallback(
    (e?: React.ChangeEvent<any>) => {
      e?.preventDefault();
      const newErrors: any = getFormErrors(values);
      setErrors(newErrors);

      if (!checkErrors(newErrors)) {
        setLoading(true);
        if (stage === RECOVER_STAGES.SET_EMAIL) {
          handleSetEmail(values.new_email).then(() => {
          });
        }

        if (stage === RECOVER_STAGES.CONFIRM_EMAIL) {
          handleSetEmailCode(values.new_email_confirmation_token).then(() => {
          });
        }

        if (stage === RECOVER_STAGES.SET_PASSWORD) {
          handleSetPassword().then(() => {
          });
        }

        if (stage === RECOVER_STAGES.YOUR_EMAIL) {
          handleYourEmail().then(() => {
          });
        }
      }
    },
    [getFormErrors, values, stage, handleSetEmail, handleSetEmailCode, handleSetPassword]
  );

  useEffect(() => {
    if (stage === RECOVER_STAGES.CONFIRM_CODE) {
      setStage(RECOVER_STAGES.SET_PASSWORD);
      setSearchParams({ stage: RECOVER_STAGES.SET_PASSWORD });
      handleSendRecoveryCode().then(() => {
      });
    }
  }, [handleSendRecoveryCode, setSearchParams, stage]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: ConfirmUpdateEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: ConfirmUpdateEmailCodeParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  return (
    <MainContent className="content-main">
      <Container>
        <OauthPasswordStyles className='confirmEmail'>
          <div className="confirmEmail-head">
            {
              stage === RECOVER_STAGES.SET_EMAIL ? (
                <span className="confirmEmail-head__title">Enter your email</span>
              ) : null
            }

            {
              stage === RECOVER_STAGES.CONFIRM_EMAIL ? (
                <span className="confirmEmail-head__title">Enter your email code</span>
              ) : null
            }

            {
              stage === RECOVER_STAGES.SET_PASSWORD ? (
                <span className="confirmEmail-head__title">Set new password</span>
              ) : null
            }

            {
              stage === RECOVER_STAGES.YOUR_EMAIL ? (
                <span className="confirmEmail-head__title">Is this your email? <b>({user.profile.email})</b></span>
              ) : null
            }
          </div>
          <div className="confirmEmail-container">
            <div className="confirmEmail-box">
              {
                stage === RECOVER_STAGES.SET_EMAIL ? (
                  <form onSubmit={onSubmitNewEmail}>
                    <Input
                      className='confirmEmail-input'
                      type="text"
                      name="new_email"
                      value={values.new_email}
                      placeholder="Enter your email"
                      error={errors.email}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="one-time-code"
                    />
                    <Button
                      className="confirmEmail-btn"
                      type="submit"
                      disabled={loading}
                    >
                      Confirm email
                      {loading ? <Loader /> : null}
                    </Button>
                  </form>
                ) : null
              }

              {
                stage === RECOVER_STAGES.CONFIRM_EMAIL ? (
                  <form onSubmit={onSubmitNewEmail}>
                    <Input
                      className='confirmEmail-input'
                      type="text"
                      name="new_email_confirmation_token"
                      value={values.new_email_confirmation_token}
                      placeholder="Enter your new email code"
                      error={errors.new_email_confirmation_token}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="one-time-code"
                    />
                    <Button
                      className="confirmEmail-btn"
                      type="submit"
                      disabled={loading}
                    >
                      Confirm email code
                      {loading ? <Loader /> : null}
                    </Button>
                  </form>
                ) : null
              }

              {
                stage === RECOVER_STAGES.SET_PASSWORD ? (
                  <form onSubmit={onSubmitNewEmail}>
                    <Input
                      className='confirmEmail-input'
                      type="text"
                      name="reset_password_token"
                      value={values.reset_password_token}
                      placeholder="Enter change password code"
                      error={errors.reset_password_token}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="one-time-code"
                    />
                    <Input
                      className='confirmEmail-input'
                      type="password"
                      name="password"
                      value={values.password}
                      placeholder="Enter new password"
                      error={errors.password}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="one-time-code"
                    />
                    <Input
                      className='confirmEmail-input'
                      type="password"
                      name="password_confirmation"
                      value={values.password_confirmation}
                      placeholder="Enter new password again"
                      error={errors.password_confirmation}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="one-time-code"
                    />
                    <Button
                      className="confirmEmail-btn"
                      type="submit"
                      disabled={loading}
                    >
                      Set password
                      {loading ? <Loader /> : null}
                    </Button>
                  </form>
                ) : null
              }

              {
                stage === RECOVER_STAGES.YOUR_EMAIL ? (
                  <form onSubmit={onSubmitNewEmail}>
                    <Input
                      className='confirmEmail-input'
                      type="text"
                      name="new_email"
                      value={user.profile.email}
                      placeholder="Enter your email"
                      error={errors.email}
                      onChange={onChange}
                      disabled
                      onBlur={onBlur}
                    />
                    <Button
                      className="confirmEmail-btn"
                      type="submit"
                      disabled={loading}
                    >
                      Confirm email
                      {loading ? <Loader /> : null}
                    </Button>
                  </form>
                ) : null
              }
            </div>
          </div>
        </OauthPasswordStyles>
      </Container>
    </MainContent>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
  };
};

export default connect(mapState, { getProfile, clear })(OauthPassword);
