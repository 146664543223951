import { STORAGE_KEYS } from '../../const/storage_keys.constants';
import { UserData } from '../../api';
import types, { AppActionTypes } from '../actionTypes';
import cloneDeep from "lodash/cloneDeep";
import {LOADING_TYPES} from "../../const/app.constants";

export type UserReducerState = {
  loggedIn: boolean;
  confirmationCodeSanded: boolean;
  tfa: {
    tfa_confirmed: boolean;
    tfa_enabled: boolean;
    secret?: string | null;
    provisioning_uri?: string | null;
    loaded: boolean;
  },
  profile: UserData;
  profileLoaded: LOADING_TYPES;
  oAuthProgress: LOADING_TYPES;
  answer: UserData;
  useTestnet: boolean;
};

const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
const auth = JSON.parse(localStorage.getItem(useTestnet ? STORAGE_KEYS.TESTNET_AUTH : STORAGE_KEYS.AUTH) || '{}');
const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER) || '{}');

const INITIAL_STATE: UserReducerState = {
  loggedIn: user.tfa_enabled ? false : !!auth.accessToken,
  confirmationCodeSanded: false,
  tfa: {
    tfa_confirmed: false,
    tfa_enabled: false,
    secret: null,
    provisioning_uri: null,
    loaded: false
  },
  profile: {
    country: user.country || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    photo: '',
    tfa_enabled: user.tfa_enabled || false
  },
  profileLoaded: LOADING_TYPES.NOT_LOADED,
  oAuthProgress: LOADING_TYPES.NOT_LOADED,
  answer: {},
  useTestnet
};

const usersReducers = (state = INITIAL_STATE, action: AppActionTypes): UserReducerState => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          country: '',
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          photo: '',
          tfa_enabled: action.payload.tfa_enabled,
        },
        loggedIn: !action.payload.tfa_enabled,
      };

    case types.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        answer: action.payload || {},
      };
    case types.CONFIRM_LOGIN_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.CONFIRM_LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          country: '',
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          photo: '',
          tfa_enabled: action.payload.tfa_enabled,
        },
        loggedIn: !action.payload.tfa_enabled,
      };

    case types.CONFIRM_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        answer: action.payload || {},
      };
    case types.REGISTRATION_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.REGISTRATION_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.REGISTRATION_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_FORGOTTEN_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_FORGOTTEN_PASSWORD_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_FORGOTTEN_PASSWORD_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        confirmationCodeSanded: true
      };
    case types.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.RESEND_CONFIRMATION_REQUEST:
      return {
        ...state,
      };
    case types.RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.RESEND_CONFIRMATION_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_TFA_REQUEST:
      return {
        ...state,
        tfa: {
          tfa_confirmed: false,
          tfa_enabled: false,
          secret: null,
          provisioning_uri: null,
          loaded: true,
        },
      };
    case types.GET_TFA_SUCCESS:
      const newTFA = action.payload || {};
      const newSecret = newTFA?.provisioning_uri ? new URLSearchParams(newTFA?.provisioning_uri.split('?')[1]).get('secret') : null;
      return {
        ...state,
        tfa: {
          tfa_confirmed: newTFA?.tfa_confirmed || false,
          tfa_enabled: newTFA?.tfa_enabled || false,
          secret: newSecret,
          provisioning_uri: newTFA?.provisioning_uri || null,
          loaded: true,
        },
      };
    case types.GET_TFA_FAILURE:
      return {
        ...state,
      };
    case types.ACTIVATE_TFA_REQUEST:
      return {
        ...state,
      };
    case types.ACTIVATE_TFA_SUCCESS:
      const activateTFASuccess = cloneDeep(state.tfa);
      activateTFASuccess.tfa_confirmed = true;
      activateTFASuccess.tfa_enabled = true;
      return {
        ...state,
        tfa: activateTFASuccess,
        answer: action.payload || {},
      };
    case types.ACTIVATE_TFA_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.TURN_OFF_TFA_REQUEST:
      return {
        ...state,
      };
    case types.TURN_OFF_TFA_SUCCESS:
      const turnOffTFASuccess = cloneDeep(state.tfa);
      turnOffTFASuccess.tfa_enabled = false;
      return {
        ...state,
        tfa: turnOffTFASuccess,
        answer: action.payload || {},
      };
    case types.TURN_OFF_TFA_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.TURN_ON_TFA_REQUEST:
      return {
        ...state,
      };
    case types.TURN_ON_TFA_SUCCESS:
      const turnOnTFASuccess = cloneDeep(state.tfa);
      turnOnTFASuccess.tfa_enabled = true;
      return {
        ...state,
        tfa: turnOnTFASuccess,
        answer: action.payload || {},
      };
    case types.TURN_ON_TFA_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.GET_PROFILE_REQUEST:
      return {
        ...state,
        profileLoaded: LOADING_TYPES.LOADING,
        confirmationCodeSanded: false
      };
    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        profileLoaded: LOADING_TYPES.LOADED
      };
    case types.GET_PROFILE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
        profileLoaded: LOADING_TYPES.LOADED
      };
    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        answer: action.payload.answer || {},
      };
    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.UPDATE_EMAIL_REQUEST:
      return {
        ...state,
      };
    case types.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile,
        answer: action.payload.answer || {},
        confirmationCodeSanded: true
      };
    case types.UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        answer: {},
      };
    case types.LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        confirmationCodeSanded: false,
        tfa: {
          tfa_confirmed: false,
          tfa_enabled: false,
          secret: null,
          provisioning_uri: null,
          loaded: false
        },
        profile: {
          country: '',
          first_name: '',
          last_name: '',
          email: '',
          photo: '',
        },
        profileLoaded: LOADING_TYPES.NOT_LOADED,
        oAuthProgress: LOADING_TYPES.NOT_LOADED,
        answer: {},
        useTestnet: false,
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
      };
    case types.CLEAR:
      return {
        ...state,
        answer: {},
      };
    case types.CHANGE_USING_TESTNET:
      return {
        ...state,
        answer: {},
      };
    case types.CHANGE_USING_TESTNET_SUCCESS:
      const newUseTestnet = action.payload.testnet;
      localStorage.setItem(STORAGE_KEYS.USE_TESTNET, newUseTestnet);
      return {
        ...state,
        useTestnet: newUseTestnet,
        answer: action.payload.answer || {},
      };
    case types.CHANGE_USING_TESTNET_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CONFIRM_CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        confirmationCodeSanded: true
      };
    case types.CONFIRM_CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.CONFIRM_CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        answer: action.payload || {},
      };
    case types.OAUTH_LOGIN_REQUEST:
      return {
        ...state,
        answer: {},
        oAuthProgress: LOADING_TYPES.LOADED,
      };
    case types.OAUTH_LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          country: '',
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
          photo: '',
        },
        loggedIn: true,
      };

    case types.OAUTH_LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        answer: action.payload || {},
      };
    default:
      return state;
  }
};

export default usersReducers;
