import React, { useCallback, useState } from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import {PhoneInputStyles, FormControlInputError, SelectElement, SelectListItem, Placeholder} from './PhoneInput.Styles';
import {ExpandMore} from "@mui/icons-material";
import {COUNTRIES, CountryWithLangs} from "../../const/countries.constants";

type PhoneInputProps = {
  className?: string;
  error?: string;
  placeholder?: string;
  name: string;
  value: string;
  onChange: (val: string, isValid: boolean) => void;
  withCountries?: boolean;
  country?: string;
};

function PhoneInput(props: PhoneInputProps) {
  const { className, value, onChange, error, placeholder, name, withCountries, country, ...rest } = props;
  const [languageCountry, setLanguageCountry] = useState<string | undefined>(country || undefined);
  const countriesList = COUNTRIES.map((country: CountryWithLangs) => {
    return {
      value: country.code.toLowerCase(),
      text: country.en
    }
  })

  const handleChangePhone = useCallback(
    (val: string, data: any) => {
      if (val) {
        let isValid: boolean = false;

        if (data.format) {
          const validNumberCount: number = (data.format.match(/\./g) || []).length;
          isValid = val.length === validNumberCount;

          if (data?.countryCode) {
            setLanguageCountry(data?.countryCode)
          }
        }

        onChange(val, isValid);
      }
    },
    [onChange]
  );

  const onCountryChange =  useCallback(
    (value: string) => {
      setLanguageCountry(value);
    },
    [setLanguageCountry]
  );

  const menuProps = {
    disablePortal: true,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    }
  };

  return (
    <PhoneInputStyles className={`${className} ${error ? '-error' : ''} ${withCountries ? '-with-countries' : ''}`}>
      {
        withCountries ? (
          <SelectElement
            MenuProps={menuProps}
            id="phoneCountry"
            className="phone-country"
            name="phoneCountry"
            value={languageCountry || ''}
            displayEmpty
            renderValue={() => languageCountry ? languageCountry.toUpperCase() : <Placeholder>US</Placeholder>}
            aria-describedby="phoneCountry"
            IconComponent={ExpandMore}
            onChange={(e: React.ChangeEvent<any>) => onCountryChange(e.target.value)}
          >
            {countriesList.map((item: any) => (
              <SelectListItem
                key={`phone-drop-down-item-${item.value}`}
                value={item.value}
              >
                {item.text}
              </SelectListItem>
            ))}
          </SelectElement>
        ) : null
      }
      <ReactPhoneInput
        specialLabel=""
        country={languageCountry}
        placeholder={placeholder}
        value={value}
        onChange={handleChangePhone}
      />
      <input type="hidden" tabIndex={-1} {...rest} />
      <FormControlInputError>{error}</FormControlInputError>
    </PhoneInputStyles>
  );
}

export default PhoneInput;
