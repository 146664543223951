import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';

import {
  Container,
  MainContent,
  BreadCrumb, Button, Select, Input, Icon, Loader, Switch, TextArea, Calendar
} from '../../../elements';
import { AppStateType } from '../../../store';
import {CreateContainer} from "../Invoices.Styles";
import {CalcFeeParams, CreateInvoicesParams} from "../../../api";
import cloneDeep from "lodash/cloneDeep";
import {selectLoadingByKey} from "../../../store/loadingsErrors/selectors";
import types from "../../../store/actionTypes";
import {FormControlLabel} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {validateEmail} from "../../../common/utils/validators";
import {formatNumber} from "../../../common/utils/formatters";
import {StoresReducerState} from "../../../store/stores/reducers";
import {createInvoice} from "../../../store/invoices/actions";
import useToast from "../../../hooks/useToast";
import {clear} from "../../../store/user/actions";
import {InvoicesReducerState} from "../../../store/invoices/reducers";
import {PATHS} from "../../../const/paths.constants";
import {useNavigate} from "react-router-dom";
import API from "../../../api/executor";
import {FeeTypeTypes} from "../../../api/codecs.invoices";

type ItemType = {
  item: string;
  qty: string;
  price: string;
  error?: { [key: string]: string }
};

export interface CreateProps {
  invoices: InvoicesReducerState;
  stores: StoresReducerState;
  createInvoice: (payload: CreateInvoicesParams) => void;
  loading: boolean;
  clear: () => void;
}

type InvoicesParams = {
  name: string;
  email: string;
  recipientAddress: string;
  address: string;
  notes: string;
  date: string;
  currency: string;
  discount: string;
  fee: string;
  items?: any
};

const CreateInvoice: React.FC<CreateProps> = (props: CreateProps) => {
  const { stores, invoices, createInvoice, loading, clear } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const currenciesList = [
    {
      value: "USD",
      text: "USD"
    },
    {
      value: "EUR",
      text: "EUR"
    }
  ]

  const [values, setValues] = useState<{ [key: string]: any }>({
    name: '',
    email: '',
    recipientAddress: '',
    address: '',
    notes: '',
    date: dayjs(new Date()),
    currency: currenciesList[0].value,
    discount: '',
    fee: ''
  });
  const [additional, setAdditional] = useState<{ [key: string]: boolean }>({
    recipientAddress: false,
    address: false,
    notes: false
  });
  const [adjustment, setAdjustment] = useState<{ [key: string]: boolean }>({
    discount: false
  });
  const [data, setData] = useState<Dayjs | null>(dayjs(new Date()));
  const [items, setItems] = useState<ItemType[]>([
    {
      item: '',
      qty: '',
      price: '',
      error: {}
    }
  ]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [priceWithFeeLoading, setPriceWithFeeLoading] = useState<boolean>(false);
  const [priceWithFee, setPriceWithFee] = useState<string>(formatNumber(0));
  const [price, setPrice] = useState<string>(formatNumber(0));
  const [lastFeeParams, setLastFeeParams] = useState<{ [key: string]: number }>({
    qty: 0,
    price: 0,
    discount: 0
  });
  const [focusedField, setFocusedField] = useState<string>('');

  let getFormErrors: (data: { [p: string]: string }) => InvoicesParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {name, email, recipientAddress, address, notes, date, currency, discount} = data;
    const newErrors: InvoicesParams = {
      name: '',
      email: '',
      recipientAddress: '',
      address: '',
      notes: '',
      date: '',
      currency: '',
      discount: '',
      fee: ''
    };

    if (!name) newErrors.name = 'Enter recipient name';
    if (!email) newErrors.email = 'Recipient email';
    if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';
    if (additional.recipientAddress && !recipientAddress) newErrors.recipientAddress = 'Enter recipient address';
    if (additional.address && !address) newErrors.address = 'Enter your address';
    if (additional.notes && !notes) newErrors.name = 'Enter notes';
    if (!Number(date)) newErrors.date = 'Select due date';
    if (!currency) newErrors.currency = 'Select currency';
    if (adjustment.discount && !Number(discount)) newErrors.discount = 'Enter discount';

    return newErrors;
  };

  useEffect(() => {
    if (invoices.answer?.success) {
      toastSuccess(invoices.answer?.success);
      navigate(PATHS.INVOICES.replace(':store', `${stores.activeStore}`));
      clear();
    }

    if (invoices.answer?.error?.error) {
      toastError(invoices.answer.error.error);
      clear();
    }
  }, [clear, invoices, navigate, stores.activeStore, toastError, toastSuccess]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: InvoicesParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }

    if (field === 'discount') {
      if (Number(lastFeeParams.discount) !== Number(value)) {
        let discountAmount: number = 0;

        items.forEach((dataItem: ItemType) => {
          discountAmount += Number(dataItem.price) * Number(dataItem.qty)
        })

        if (adjustment.discount) {
          discountAmount = discountAmount * ((100 - Number(value)) / 100)
        }

        calcPriceWithFee(discountAmount).then(() => {
          setLastFeeParams(prev => ({
            ...prev,
            discount: Number(value),
          }))
        });
      }
    }
  };

  const onBlur = (field: string, value?: number | string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: InvoicesParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onSetDate = (timestamp: Dayjs | null) => {
    onChange('date', String(timestamp?.unix() || ''))
    setData(timestamp);
  };

  const toggleAdditional = useCallback((field: string) => {
    setAdditional(prev => ({
      ...prev,
      [field]: !additional[field],
    }));
  }, [additional, setAdditional]);

  const toggleAdjustment = useCallback((field: string) => {
    setAdjustment(prev => ({
      ...prev,
      [field]: !adjustment[field],
    }));
  }, [adjustment, setAdjustment]);

  const addNewItem = () => {
    const newItems = cloneDeep(items);
    newItems.push({
      item: '',
      qty: '',
      price: '',
      error: {}
    })
    setItems(newItems);
  };

  const onItemRemove = (index: number) => {
    const newItems = cloneDeep(items);
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const getItemErrors = (data: ItemType[]): ItemType[] => {
    return data.map((dataItem) => {
      const { item, qty, price } = dataItem;
      const newErrors: { [key: string]: string } = {
        item: '',
        qty: '',
        price: ''
      };

      if (!item) newErrors.item = 'Enter item';
      if (!Number(qty)) newErrors.qty = 'Enter quanity';
      if (!Number(price)) newErrors.price = 'Enter price';

      dataItem.error = newErrors;
      return dataItem;
    });
  };

  const onChangeItem = (field: string, value: string) => {
    const itemIndex = Number(field.split('#')[1]);
    const itemName = field.split('#')[0];
    const newItems = cloneDeep(items);
    newItems[itemIndex][itemName] = value;

    const newDataErrors: ItemType[] = getItemErrors(newItems);
    setItems(newDataErrors);

    if (itemName === 'price' || itemName === 'qty') {
      if (Number(lastFeeParams[field]) !== Number(value)) {
        let amount: number = 0;

        newItems.forEach((dataItem: ItemType) => {
          amount += Number(dataItem.price) * Number(dataItem.qty)
        })

        if (adjustment.discount) {
          amount = amount * ((100 - Number(values.discount)) / 100)
        }
        calcPriceWithFee(amount).then(() => {
          setLastFeeParams(prev => ({
            ...prev,
            [field]: Number(value),
          }))
        });
      }
    }
  };

  const calcPriceWithFee = async (amount: number) => {
    const priceWithFeeProps: CalcFeeParams = {
      storeId: stores.activeStore || '',
      feeType: `${FeeTypeTypes.DEPOSIT},${FeeTypeTypes.CREDIT}`,
      amount,
      currency: values.currency,
    };
    const getPriceWithFeeCall = (payload: CalcFeeParams) => API.call('calcFee', payload);
    setPriceWithFeeLoading(true);
    await getPriceWithFeeCall(priceWithFeeProps).then((feeAmount: any) => {
      setPriceWithFee(formatNumber(feeAmount));
      setPrice(formatNumber(amount));
      setPriceWithFeeLoading(false);
    })
  }

  const onItemBlur = (field: string) => {
    const itemIndex = Number(field.split('#')[1]);
    const itemName = field.split('#')[0];

    if (Object.prototype.hasOwnProperty.call(items[itemIndex].error, itemName)) {
      const newItems = cloneDeep(items);
      const newDataErrors: ItemType[] = getItemErrors(newItems);

      setItems(newDataErrors);
    }
  };

  const checkItemsErrors = (data: ItemType[]) => {
    let hasError = false
    data.forEach((dataItem) => {
      for (const error in dataItem.error) {
        if (dataItem.error[error]) { hasError = true }
      }
    })
    return hasError;
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: InvoicesParams = getFormErrors(values);
      setErrors(newErrors);
      const newItemErrors: ItemType[] = getItemErrors(items);

      let amount: number = 0;

      items.forEach((dataItem: ItemType) => {
        amount += Number(dataItem.price) * Number(dataItem.qty)
      })

      if (adjustment.discount) {
        amount = amount * ((100 - Number(values.discount)) / 100)
      }

      const create: CreateInvoicesParams = {
        body: {
          customer_email: values.email,
          currency: values.currency,
          amount,
          data: {
            customer_name: values.name,
            customer_address: values.recipientAddress,
            address: values.address,
            notes: values.notes,
            due_date: values.date,
            discount: values.discount,
          }
        },
        storeId: stores.activeStore || ''
      };

      create.body.data.items = items.map((dataItem) => {
        return {
          item: dataItem.item,
          qty: dataItem.qty,
          price: dataItem.price
        }
      })

      if (!checkErrors(newErrors) && !checkItemsErrors(newItemErrors)) {
        createInvoice(create);
      } else {
        if (checkErrors(newErrors)) {
          const errArr = Object.keys(newErrors).filter((name) => !!newErrors[name]);

          if (!!errArr.length) {
            setFocusedField(errArr[0]);
          }
        } else {
          let newFocusedField = '';
          newItemErrors.forEach((dataItem: ItemType, index) => {
            // @ts-ignore
            const errArr = Object.keys(dataItem.error).filter((name) => !!dataItem.error[name]);
            errArr.forEach((error) => {
              if (!newFocusedField) {
                newFocusedField = `${error}#${index}`;
              }
            });
          });
          setFocusedField(newFocusedField);
        }
      }
    },
    [getFormErrors, values, items, adjustment.discount, stores.activeStore, createInvoice]
  );

  return (
    <MainContent className="content-main">
      <Container>
        <BreadCrumb />
        <CreateContainer>
          <div className="create-title__wrap">
            <span className="create-title">Create invoice</span>
          </div>
          <form onSubmit={onSubmit}>
            <div className="grid-x create">
              <div className="cell small-12 xlarge-45">

                <Input
                  className='create-input'
                  type="text"
                  name="name"
                  value={values.name}
                  placeholder="Recipient name"
                  label="Recipient name"
                  error={errors.name}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={focusedField === 'name'}
                  setOnFocus={setFocusedField}
                />

                <Input
                  className='create-input'
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder="Recipient email"
                  label="Recipient email"
                  error={errors.email}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={focusedField === 'email'}
                  setOnFocus={setFocusedField}
                />

                <div className='create-additional'>
                  <div className="create-additional__title_wrap">
                    <span className="create-additional__title">Do you want to add any additional information?</span>
                  </div>
                  <div className="create-additional__row">
                    <FormControlLabel
                      className="switch"
                      control={
                        <Switch
                          className="create-additional__switch"
                          checked={additional.recipientAddress}
                          onChange={() => toggleAdditional('recipientAddress')}
                          name="checkedRecipientAddress"
                          color="primary"
                        />
                      }
                      label="Recipient’s address"
                    />

                    {
                      additional.recipientAddress ? (
                        <TextArea
                          className='create-textarea'
                          type="text"
                          name="recipientAddress"
                          value={values.recipientAddress}
                          placeholder="Enter a recipient’s address"
                          error={errors.recipientAddress}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={focusedField === 'recipientAddress'}
                          setOnFocus={setFocusedField}
                        />
                      ) : null
                    }
                  </div>

                  <div className="create-additional__row">
                    <FormControlLabel
                      className="switch"
                      control={
                        <Switch
                          className="create-additional__switch"
                          checked={additional.address}
                          onChange={() => toggleAdditional('address')}
                          name="checkedRecipientAddress"
                          color="primary"
                        />
                      }
                      label="Your address"
                    />

                    {
                      additional.address ? (
                        <TextArea
                          className='create-textarea'
                          type="text"
                          name="address"
                          value={values.address}
                          placeholder="Enter your address"
                          error={errors.address}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={focusedField === 'address'}
                          setOnFocus={setFocusedField}
                        />
                      ) : null
                    }
                  </div>

                  <div className="create-additional__row">
                    <FormControlLabel
                      className="switch"
                      control={
                        <Switch
                          className="create-additional__switch"
                          checked={additional.notes}
                          onChange={() => toggleAdditional('notes')}
                          name="checkedNotes"
                          color="primary"
                        />
                      }
                      label="Notes"
                    />

                    {
                      additional.notes ? (
                        <TextArea
                          className='create-textarea'
                          type="text"
                          name="notes"
                          value={values.notes}
                          placeholder="Enter a notes"
                          error={errors.notes}
                          onChange={onChange}
                          onBlur={onBlur}
                          onFocus={focusedField === 'notes'}
                          setOnFocus={setFocusedField}
                        />
                      ) : null
                    }
                  </div>
                </div>

                <Calendar
                  className="create-calendar"
                  label="Due date"
                  data={data}
                  error={errors.date}
                  setData={onSetDate}
                />

                <Select
                  className="create-select"
                  name="currency"
                  value={values.currency}
                  placeholder="Currency"
                  error={errors.currency}
                  list={currenciesList}
                  // image={`/img/fiat/{values.currency}.svg`}
                  label="Currency"
                  fullWidth
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="grid-x">
              <div className="cell small-12 xlarge-65">
                <div className="create-items">
                  {
                    items.map((item, index: number) => (
                      <div
                        key={`create-items__${index + 1}`}
                        className={`create-items__row ${!!index ? '-second' : ''}`}
                      >
                        <Input
                          className="create-input -big"
                          type="text"
                          name={`item#${index}`}
                          value={item.item}
                          placeholder="Item"
                          label={`Item ${!!Number(index) ? '#' + Number(index + 1) : ''}`}
                          error={item.error?.item}
                          onChange={onChangeItem}
                          onBlur={onItemBlur}
                          onFocus={focusedField === `item#${index}`}
                          setOnFocus={setFocusedField}
                        />
                        <Input
                          className='create-input'
                          type="number"
                          name={`qty#${index}`}
                          value={item.qty}
                          placeholder="Quanity"
                          label="Quanity"
                          error={item.error?.qty}
                          onChange={onChangeItem}
                          onBlur={onItemBlur}
                          onFocus={focusedField === `qty#${index}`}
                          setOnFocus={setFocusedField}
                        />
                        <Input
                          className='create-input'
                          type="number"
                          name={`price#${index}`}
                          value={item.price}
                          placeholder="0.00"
                          label={`Price (${values.currency})`}
                          error={item.error?.price}
                          onChange={onChangeItem}
                          onBlur={onItemBlur}
                          onFocus={focusedField === `price#${index}`}
                          setOnFocus={setFocusedField}
                        />

                        {
                          !!index ? (
                            <button
                              className="create-items__remove"
                              onClick={() => onItemRemove(index)}
                              type="button"
                            >
                              <Icon name="trash" size="20" />
                            </button>
                          ) : null
                        }
                      </div>
                    ))
                  }

                  <div className='create-items__row -add'>
                    <button
                      className="create-items__btn -add"
                      onClick={() => addNewItem()}
                      type="button"
                    >
                      <Icon name="plus" size="14" />
                      <span className="create-items__text">Add item</span>
                    </button>
                  </div>

                </div>

              </div>
            </div>
            <div className="grid-x">
              <div className="cell small-12 xlarge-45">

                <div className='create-additional -adjustment'>
                  <div className="create-additional__title_wrap">
                    <span className="create-additional__title">Do you want to add any price adjustment?</span>
                  </div>
                  <div className="create-additional__row">
                    <FormControlLabel
                      className="switch"
                      control={
                        <Switch
                          className="create-additional__switch"
                          checked={adjustment.discount}
                          onChange={() => toggleAdjustment('discount')}
                          name="checkedDiscount"
                          color="primary"
                        />
                      }
                      label="Add discount"
                    />

                    {
                      adjustment.discount ? (
                        <Input
                          className='create-input -adjustment'
                          type="number"
                          name="discount"
                          value={values.discount}
                          placeholder="Discount (%)"
                          error={errors.discount}
                          onChange={onChange}
                          onBlur={onBlur}
                          maxNumber={100}
                          onFocus={focusedField === 'discount'}
                          setOnFocus={setFocusedField}
                        />
                      ) : null
                    }
                  </div>
                  {/*<div className="create-additional__row">*/}
                  {/*  <FormControlLabel*/}
                  {/*    className="switch"*/}
                  {/*    control={*/}
                  {/*      <Switch*/}
                  {/*        className="create-additional__switch"*/}
                  {/*        checked={adjustment.fee}*/}
                  {/*        onChange={() => toggleAdjustment('fee')}*/}
                  {/*        name="checkedFee"*/}
                  {/*        color="primary"*/}
                  {/*      />*/}
                  {/*    }*/}
                  {/*    label="Include processing fee"*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>

            <div className="create-total">
              <span className="create-total__title">Total</span>
              {
                priceWithFeeLoading ? (
                  <>
                    <span className="create-total__amount"><Loader /> {values.currency}</span>
                    <span className="create-total__title_sub">Reimbursable for nominal transaction:</span>
                    <span className="create-total__amount_sub"><Loader /> {values.currency}</span>
                  </>
                ) : (
                  <>
                    <span className="create-total__amount">{price} {values.currency}</span>
                    <span className="create-total__title_sub">Reimbursable for nominal transaction:</span>
                    <span className="create-total__amount_sub">{priceWithFee} {values.currency}</span>
                  </>
                )
              }
            </div>

            <div className="create-btns">
              <Button
                className="create-btn loading-btn"
                type="submit"
                disabled={loading || priceWithFeeLoading}
              >
                Create invoice
                {loading ? <Loader /> : null}
              </Button>

              <Button
                className="create-btn -white"
                type="button"
              >
                Cancel
              </Button>
            </div>

          </form>

        </CreateContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { stores, invoices } = state;
  return {
    invoices,
    stores,
    loading: selectLoadingByKey(state, types.CREATE_INVOICE_REQUEST),
  };
};

export default connect(mapStateToProps, {createInvoice, clear})(CreateInvoice);
