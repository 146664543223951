import styled from 'styled-components';

export const LoginStyles: any = styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 1030px;
  display: flex;
  font-family: var(--inter);

  .login {
    &-container {
      position: relative;
      flex: 1;
      background: var(--white);
    }
    
    &-grid {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    
    &-top {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2rem;
    }
    
    &-logo {
      
    }
    
    &-center {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }
    
    &-box {
      display: flex;
      flex-direction: column;
      min-width: 360px;
      width: 55%;
    }
    
    &-title {
      
    }
    
    &-select {
      margin-top: 2rem;
    }

    &-input {
      margin-top: 20px;
      
      &__undertext {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        margin-top: 6px;
      }
    }

    &-checkbox {
      align-items: center;
      text-align: left;
      display: flex;
      margin-top: 31px;
      margin-left: -3px;

      a {
        display: inline;
        color: var(--dark-purple);
      }
      
      .MuiSvgIcon-root {
        width: 23px;
        height: 23px;
      }

      .MuiFormControlLabel-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        font-family: var(--inter);
      }

      .MuiCheckbox-root {
        padding: 0;
        margin-right: 4px;
        color: var(--light-gray);

        &.Mui-checked {
          color: var(--dark-gray);
        }
      }

      + p {
        display: none;
        animation: fadeIn 0.8s;
      }

      &.-error {
        .MuiSvgIcon-root {
          fill: var(--red);
        }

        + p {
          display: block;
        }
      }
    }
    
    &-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;

      &.-google {
        background-color: #3b5998;
        &:before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          width: 18px;
          height: 18px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjY2NjUgMTQuNDQ4M0MyNS42NjcyIDEzLjYzNTggMjUuNTk1MSAxMi44MjUgMjUuNDUxMiAxMi4wMjU0SDE0LjI5NTJWMTYuNjA3M0gyMC42N0MyMC4zOTU0IDE4LjA4OCAxOS41NjA5IDE5LjM0MjUgMTguMzA2NCAyMC4xODI0VjIzLjE1NDRIMjIuMTM0NUMyNC4zNzQzIDIxLjA5MjMgMjUuNjY2NSAxOC4wNTU2IDI1LjY2NjUgMTQuNDQ4M1oiIGZpbGw9IiM0Mjg1RjQiLz4KPHBhdGggZD0iTTE0LjI5NTIgMjYuMDIyNEMxNy40OTM0IDI2LjAyMjQgMjAuMTc0NyAyNC45NjE3IDIyLjEzNDYgMjMuMTUyNkwxOC4zMDY0IDIwLjE4MDVDMTcuMjQ1NyAyMC44OTEyIDE1Ljg4ODkgMjEuMzExMiAxNC4yOTUyIDIxLjMxMTJDMTEuMjEwMSAyMS4zMTEyIDguNTk4NzggMTkuMjI3NSA3LjY2NzMyIDE2LjQyNzdIMy43MDk5NlYxOS40OTY3QzUuNjU5MDIgMjMuMzY4IDkuNjY0ODIgMjYuMDIyNCAxNC4yOTUyIDI2LjAyMjRaIiBmaWxsPSIjMzRBODUzIi8+CjxwYXRoIGQ9Ik03LjY2NzM0IDE2LjQyOTVDNy4xNzIgMTQuOTcgNy4xNzIgMTMuMzg3OSA3LjY2NzM0IDExLjkyODRWOC44NTkzOEgzLjcwOTk3QzIuODgxNSAxMC41MTAzIDIuNDUwMDcgMTIuMzMxOCAyLjQ1MDA3IDE0LjE3ODlDMi40NTAwNyAxNi4wMjYgMi44ODE1IDE3Ljg0NzYgMy43MDk5NyAxOS40OTg1TDcuNjY3MzQgMTYuNDI5NVoiIGZpbGw9IiNGQkJDMDUiLz4KPHBhdGggZD0iTTE0LjI5NTEgNy4wNDUxM0MxNi4wMzQyIDcuMDQ1MTMgMTcuNTk1NiA3LjY0Mjc4IDE4LjgyMzIgOC44MTY1MkwyMi4yMjA2IDUuNDE5MTFDMjAuMTY5MiAzLjUwNzcyIDE3LjQ4NzkgMi4zMzM5OCAxNC4yOTUxIDIuMzMzOThDOS42NjQ3MiAyLjMzMzk4IDUuNjU4OSA0Ljk4ODM5IDMuNzA5ODQgOC44NTk1OUw3LjY2NzIyIDExLjkyODZDOC41OTg2OCA5LjEyODg1IDExLjIxIDcuMDQ1MTYgMTQuMjk1MSA3LjA0NTE2VjcuMDQ1MTNaIiBmaWxsPSIjRUE0MzM1Ii8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: cover;
        }

        &:hover {
          color: var(--dark-white);
        }
      }

      &.-github {
        background-color: #55acee;
        border-color: #55acee;
        &:before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          width: 18px;
          height: 18px;
          background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAwQzYuMjY1IDAgMCA2LjI2NSAwIDE0QzAgMjAuMTk1IDQuMDA3NSAyNS40Mjc1IDkuNTcyNSAyNy4yODI1QzEwLjI3MjUgMjcuNDA1IDEwLjUzNSAyNi45ODUgMTAuNTM1IDI2LjYxNzVDMTAuNTM1IDI2LjI4NSAxMC41MTc1IDI1LjE4MjUgMTAuNTE3NSAyNC4wMUM3IDI0LjY1NzUgNi4wOSAyMy4xNTI1IDUuODEgMjIuMzY1QzUuNjUyNSAyMS45NjI1IDQuOTcgMjAuNzIgNC4zNzUgMjAuMzg3NUMzLjg4NSAyMC4xMjUgMy4xODUgMTkuNDc3NSA0LjM1NzUgMTkuNDZDNS40NiAxOS40NDI1IDYuMjQ3NSAyMC40NzUgNi41MSAyMC44OTVDNy43NyAyMy4wMTI1IDkuNzgyNSAyMi40MTc1IDEwLjU4NzUgMjIuMDVDMTAuNzEgMjEuMTQgMTEuMDc3NSAyMC41Mjc1IDExLjQ4IDIwLjE3NzVDOC4zNjUgMTkuODI3NSA1LjExIDE4LjYyIDUuMTEgMTMuMjY1QzUuMTEgMTEuNzQyNSA1LjY1MjUgMTAuNDgyNSA2LjU0NSA5LjUwMjVDNi40MDUgOS4xNTI1IDUuOTE1IDcuNzE3NSA2LjY4NSA1Ljc5MjVDNi42ODUgNS43OTI1IDcuODU3NSA1LjQyNSAxMC41MzUgNy4yMjc1QzExLjY1NSA2LjkxMjUgMTIuODQ1IDYuNzU1IDE0LjAzNSA2Ljc1NUMxNS4yMjUgNi43NTUgMTYuNDE1IDYuOTEyNSAxNy41MzUgNy4yMjc1QzIwLjIxMjUgNS40MDc1IDIxLjM4NSA1Ljc5MjUgMjEuMzg1IDUuNzkyNUMyMi4xNTUgNy43MTc1IDIxLjY2NSA5LjE1MjUgMjEuNTI1IDkuNTAyNUMyMi40MTc1IDEwLjQ4MjUgMjIuOTYgMTEuNzI1IDIyLjk2IDEzLjI2NUMyMi45NiAxOC42Mzc1IDE5LjY4NzUgMTkuODI3NSAxNi41NzI1IDIwLjE3NzVDMTcuMDggMjAuNjE1IDE3LjUxNzUgMjEuNDU1IDE3LjUxNzUgMjIuNzY3NUMxNy41MTc1IDI0LjY0IDE3LjUgMjYuMTQ1IDE3LjUgMjYuNjE3NUMxNy41IDI2Ljk4NSAxNy43NjI1IDI3LjQyMjUgMTguNDYyNSAyNy4yODI1QzIxLjI0MTggMjYuMzQ0MyAyMy42NTY4IDI0LjU1ODEgMjUuMzY3NyAyMi4xNzUzQzI3LjA3ODYgMTkuNzkyNiAyNy45OTkzIDE2LjkzMzQgMjggMTRDMjggNi4yNjUgMjEuNzM1IDAgMTQgMFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
          background-repeat: no-repeat;
          background-size: cover;
        }

        &:hover {
          color: var(--dark-white);
        }
      }
    }

    &-or {
      padding: 30px 0 0 0;
      text-align: center;
      position: relative;

      span {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-gray);
        background: #fff;
        padding: 0 28px;
        position: relative;
        z-index: 2;
      }

      &:after {
        content: '';
        position: absolute;
        top: 43px;
        left: 0;
        width: 100%;
        height: 1px;
        background: var(--light-gray);
      }
    }
    
    &-entrance {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      p {
        margin: 0;
        color: var(--gray);
      }

      a {
        color: var(--dark-purple);
        margin-left: 4px;

        &:focus {
          text-shadow: 1px 1px 2px #E0EAFF;
        }
      }
      
      &__link {
        color: var(--dark-purple);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border-radius: 0;
        border: none;
        background: none;
        box-shadow: none;
        outline: none;
        padding: 0;
        margin-left: 4px;
      }
    }
    
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      
      &__copyright {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        
        &_wrap {
          
        }
      }
      
      &__link {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray);
        
        &_wrap {
          z-index: 10;
        }
      }
    }
    
    &-image {
      display: flex;
      position: relative;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex: 1;
      background: linear-gradient(45deg, #101828 0%, #475467 100%);
      
      &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      
      &__box {
        display: flex;
        flex-direction: column;
        width: 73%;
        min-width: 400px;
      }
      
      &__star {
        width: 80px;
        height: 80px;
      }
      
      &__title {
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: -0.02em;
        color: var(--white);
        margin-top: 32px;
      }

      &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--dark-white);
        margin-top: 24px;
      }
      &__avatars {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        position: relative;
        
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--dark-white);
          margin-top: 9px;
        }
      }

      &__avatar {
        margin-right: 30px;
        
        &_img {
          width: 43px;
          height: 43px;
          margin-right: -15px;
        }
      }
      
      &__arrow {
        width: 333px;
        height: 100px;
        position: absolute;
        top: 58px;
        left: -243px;
      }
    }
  }

  .tfa {
    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);

      &__wrap {
        margin-top: 31px;
        padding-bottom: 19px;
        border-bottom: 1px solid var(--dark-white);
      }
    }

    &-wrap {
      margin-top: 24px;
      padding-bottom: 9px;
      border-bottom: 1px solid var(--dark-white);
    }

    &-row {

    }

    &-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: var(--dark-gray);
    }

    &-qrcode {

    }

    &-copy {
      display: flex;
      min-width: 370px;
    }

    &-code {
      width: 48px;
      height: 48px;
      border: 1px solid var(--light-gray);
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: var(--border-radius);
      font-weight: 500;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      letter-spacing: -0.02em;
      color: var(--purple);
      outline: none;

      &::placeholder {
        color: var(--light-gray);
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
      }

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }

      &__wrap {
        margin-top: 6px;
      }

      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
      }
    }

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 16px;
    }

    &-btn {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 15px;
    }
  }

  @media only screen and (max-width: 85.3125em) {
    .login {
      &-image {
        &__arrow {
          display: none;
        }
      }
    }
  }

`;
