import React, {useCallback, useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Title,
  Loader,
  Logo,
} from '../../../elements';
import { LoginStyles } from './Login.Styles';
import {ConfirmLogInParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import {clear, confirmLogin, logout} from '../../../store/user/actions';
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import cloneDeep from "lodash/cloneDeep";
import AuthCode, {AuthCodeRef} from "react-auth-code-input";

export interface ConfirmLoginProps {
  user: UserReducerState;
  confirmLogin: (payload: ConfirmLogInParams) => void;
  logout: () => void;
  clear: () => void;
  loading: boolean;
}

const ConfirmLogin: React.FC<ConfirmLoginProps> = (props: ConfirmLoginProps) => {
  const { user, confirmLogin, clear, loading, logout } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();
  const tfaInputRef = useRef<AuthCodeRef>(null);
  const [values, setValues] = useState<{ [key: string]: string }>({
    tfa_code: '',
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const setInitialData = () => {
    setValues(prev => ({
      ...prev,
      tfa_code: '',
    }));

    tfaInputRef.current?.clear();
  }

  let getFormErrors: (data: { [p: string]: string }) => ConfirmLogInParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {tfa_code} = data;
    const newErrors: ConfirmLogInParams = {
      tfa_code: '',
    };

    if (!tfa_code) newErrors.tfa_code = 'Enter 2FA code';

    return newErrors;
  };

  const onSubmit = useCallback(
    (e?: React.ChangeEvent<any>) => {
      e?.preventDefault();
      const newErrors: ConfirmLogInParams = getFormErrors(values);
      // @ts-ignore
      setErrors(newErrors);

      const confirmData: ConfirmLogInParams = {
        tfa_code: values.tfa_code,
      };

      // @ts-ignore
      if (!checkErrors(newErrors)) {
        confirmData.user = user.profile;
        confirmLogin(confirmData);
      }
    },
    [values, confirmLogin, getFormErrors]
  );

  useEffect(() => {
    if (user.answer?.success) {
      toastSuccess(user.answer?.success);

      clear();
      setInitialData();
    }

    if (user.answer?.error?.tfa_code) {
      const newErrors: ConfirmLogInParams = {
        tfa_code: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      // @ts-ignore
      setErrors(newErrors);

      clear();
      setInitialData();
    }

    if (user.answer?.error?.error) {
      toastError(user.answer?.error?.error);
      clear();
      setInitialData();
    }

    if (!user.profile.email) {
      navigate(PATHS.LOGIN);
    }
  }, [user, toastError, toastSuccess, clear, onSubmit]);

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: ConfirmLogInParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const handleAuthCode = (res: string) => {
    onChange('tfa_code', res);
  };

  const clearCookie = () => {
    logout();
  };

  return (
    <LoginStyles className='login'>
      <div className="login-container">
        <div className="grid-container login-grid">
          <div className="login-top">
            <Logo className="login-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="login-center">
            <div className="login-box">

              <Title className='login-title'>
                Login confirmation
              </Title>
              <form onSubmit={onSubmit}>
                <div className="tfa-code__wrap">
                  <AuthCode
                    ref={tfaInputRef}
                    containerClassName="tfa-code__container"
                    inputClassName="tfa-code"
                    allowedCharacters="numeric"
                    length={6}
                    placeholder="0"
                    autoFocus={false}
                    onChange={handleAuthCode}
                  />
                </div>
                <Button
                  className="login-btn"
                  type="submit"
                  disabled={loading}
                >
                  Verify
                  {loading ? <Loader/> : null}
                </Button>
              </form>

              <div className="login-entrance">
                <p>Remember your password? </p>
                <Button
                  type="button"
                  className="login-entrance__link"
                  onClick={() => clearCookie()}
                >
                  Login
                </Button>
              </div>

            </div>
          </div>
          <div className="login-bottom">
            <div className="login-bottom__copyright_wrap">
              <span className="login-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="login-bottom__link_wrap">
              <Link tabIndex={-1} className="login-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="login-bottom__link"> & </span>
              <Link tabIndex={-1} className="login-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="login-image">
        <div className="login-image__container">
          <div className="login-image__box">
            <div className="login-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="login-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="login-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="login-image__avatars">
              <div className="login-image__avatar">
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="login-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="login-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.CONFIRM_LOGIN_REQUEST),
  };
};

export default connect(mapState, { confirmLogin, clear, logout })(ConfirmLogin);
