import CryptoJs from 'crypto-js';
import { API_URL, API_TESTNET_URL } from '../const/general.constants';
import { STORAGE_KEYS } from '../const/storage_keys.constants';

import { ApiHandlerParams, ApiHandlerTypes, getHandler, ApiHandlerResponse } from './calls';
import { ApiError, isApiError } from './error';
import { Request, FormDataEntry } from './request';

type ErrorHandler = (e: ApiError) => void;

type ProtoError = {
  error: string;
  errors: string[];
};

const extraGoodHttpStatuses = new Set<number>([204]);
const unauthorizedHttpStatuses = new Set<number>([401]);
const unprocessableHttpStatuses = new Set<number>([422]);

function processFormData(form: FormDataEntry[]) {
  if (!Array.isArray(form)) return null;
  const res = new FormData();
  for (const entry of form) {
    res.append(entry.name, entry.value);
  }
  return res;
}

class Api {
  private timeout: number;

  private errorHandler?: ErrorHandler;

  constructor() {
    this.timeout = 60 * 1000;
  }

  call<Type extends ApiHandlerTypes>(
    type: Type,
    params: ApiHandlerParams<Type>
  ): Promise<ApiHandlerResponse<Type>>;
  call(type: any, params: any): any {
    const h = getHandler(type);
    const req = h.prepare(params);
    const a = this.makeRequest(req, type).then((data: any) => h.decode(data));
    if (this.errorHandler) a.catch(this.errorHandler);
    return a;
  }

  private executeFetch(url: string, params: Request, auth: { accessToken: string, ownerUid: string }, type: string): Promise<any> {
    const headers: Record<string, string> = {
      ...params.headers,
    };
    const i: number = new Date().getTime();
    let s: string = '' + i;

    if (!!auth.accessToken) {
      headers['x-auth-token'] = `${auth.accessToken}`;
      s += auth.accessToken;
    }

    s += `/api/lk/v1${params.path}`;

    if (!!auth.ownerUid) {
      headers['x-auth-owner-uid'] = `${auth.ownerUid}`;
    }

    const jsonData = params.data ? JSON.stringify(params.data) : processFormData(params.form);

    if (params.data) {
      headers['Content-Type'] = 'application/json';
      s += JSON.stringify(params.data);
    }

    const h: string = CryptoJs.SHA256(s).toString(CryptoJs.enc.Hex);

    headers['X-I'] = String(i);
    headers['X-S'] = h;

    const init: RequestInit = {
      method: params.method,
      headers,
      body: jsonData,
    };

    const abortController = new AbortController();
    init.signal = abortController.signal;

    let timeoutHandle: ReturnType<typeof setTimeout> | undefined = setTimeout(
      () => abortController.abort(),
      this.timeout
    );
    const cleanupTimeout = () => {
      if (!timeoutHandle) return;
      clearTimeout(timeoutHandle);
      timeoutHandle = undefined;
    };

    // TODO: appProvider.application.logger.info('lib request', url, init);

    return fetch(url, init)
      .then(res => {
        if (res.ok || extraGoodHttpStatuses.has(res.status)) {
          const contentType = res.headers.get('Content-Type');
          const contentLength = res.headers.get('content-length');

          if (contentType === 'text/plain' || contentLength === '0') {
            return Promise.resolve({});
          }

          if (contentType === 'application/pdf') {
            return res.blob().then((blob: Blob) => {
              return blob;
            });
          }

          if (contentType === 'text/csv') {
            return res.blob().then((blob: Blob) => {
              return blob;
            });
          }

          return res.json().then(r => {
            const responseData: any = r.id ? {data: r} : r.meta ? {data: r.data, meta: r.meta} : r.data || { data: r } || {};
            return responseData;
          });
        }

        console.log('res.status', res.status);

        if (unauthorizedHttpStatuses.has(res.status)) {
          console.log('unauthorizedHttpStatuses');
          const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
          if (useTestnet) {
            console.log('unauthorizedHttpStatuses useTestnet');
            localStorage.removeItem(STORAGE_KEYS.ACTIVE_TESTNET_STORE);
            localStorage.removeItem(STORAGE_KEYS.USE_TESTNET);
            localStorage.removeItem(STORAGE_KEYS.TESTNET_AUTH);
          } else {
            console.log('unauthorizedHttpStatuses mainnet');
            localStorage.removeItem(STORAGE_KEYS.AUTH);
            localStorage.removeItem(STORAGE_KEYS.USER);
            localStorage.removeItem(STORAGE_KEYS.ACTIVE_STORE);
            localStorage.removeItem(STORAGE_KEYS.ACTIVE_TESTNET_STORE);
            localStorage.removeItem(STORAGE_KEYS.USE_TESTNET);
            localStorage.removeItem(STORAGE_KEYS.TESTNET_AUTH);
            document.location.reload();
            return Promise.resolve({});
          }
        }

        if (unprocessableHttpStatuses.has(res.status)) {
          console.log('unprocessableHttpStatuses');
          const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
          if (useTestnet && (type === 'getTestnetToken' || type === 'signInTestnet')) {
            localStorage.removeItem(STORAGE_KEYS.ACTIVE_TESTNET_STORE);
            localStorage.removeItem(STORAGE_KEYS.USE_TESTNET);
            localStorage.removeItem(STORAGE_KEYS.TESTNET_AUTH);
          }
        }

        const err = new ApiError('NetworkError');
        err.httpStatus = res.status;
        err.httpStatusText = res.statusText;
        err.message = '';

        return res.text().then((text: string) => {
          try {
            const data = JSON.parse(text) as ProtoError;
            const error = data.errors ? data.errors : data;
            const errorDetail: Record<string, string> = {};
            for (const i in error) {
              if (Object.prototype.hasOwnProperty.call(error, i)) {
                const e = error[i];

                if (e instanceof Array) {
                  err.message = `${i}: ${e.join(';')}`;
                  errorDetail[i] = e.join(';');
                } else {
                  if (e.includes('<!DOCTYPE html>')) {
                    errorDetail[i] = "We're sorry, but something went wrong (500)";
                  } else {
                    errorDetail[i] = e;
                  }
                }
              }
            }
            if (Object.prototype.hasOwnProperty.call(errorDetail, 'detail')) {
              err.message = errorDetail.detail;
            }
            err.details = errorDetail;
            err.errObj = errorDetail;
          } catch (e) {
            err.message = text;
          }
          throw err;
        });
      })
      .catch((e: Error) => {
        const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
        if (useTestnet && (type === 'getTestnetToken' || type === 'signInTestnet')) {
          localStorage.removeItem(STORAGE_KEYS.ACTIVE_TESTNET_STORE);
          localStorage.removeItem(STORAGE_KEYS.USE_TESTNET);
          localStorage.removeItem(STORAGE_KEYS.TESTNET_AUTH);
        }

        if (e.toString().includes('<!DOCTYPE html>')) {
          const err500 = new ApiError('NetworkError');
          err500.message = "We're sorry, but something went wrong (500)";
          err500.errObj = {error: "We're sorry, but something went wrong (500)"};
          throw err500;
        }

        if (e.toString().includes('Failed to fetch')) {
          const err500 = new ApiError('NetworkError');
          err500.message = "We're sorry, but something went wrong (500)";
          err500.errObj = {error: "We're sorry, but something went wrong (500)"};
          throw err500;
        }

        if (isApiError(e)) throw e;
        throw new ApiError('NetworkError', e.toString());
      })
      .finally(() => {
        cleanupTimeout();
      });
  }

  private makeRequest(params: Request, type: string): Promise<any> {
    const useTestnet = localStorage.getItem(STORAGE_KEYS.USE_TESTNET) === 'true';
    const apiURL = useTestnet ? API_TESTNET_URL : API_URL
    const url = `${apiURL}${params.path}`;
    let auth = JSON.parse(localStorage.getItem(useTestnet ? STORAGE_KEYS.TESTNET_AUTH : STORAGE_KEYS.AUTH) || '{}');

    return this.executeFetch(url, params, auth, type);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api();
