import {
  logIn,
  registration,
  forgotPassword,
  updateForgottenPassword,
  confirmEmailCode,
  resendConfirmationCode,
  updatePassword,
  getTestnetToken,
  signInTestnet,
  OAuthlogIn,
  confirmLogIn,
} from './codecs.auth';

import {
  createStore,
  getStores,
  createAPIKey,
  getAPIKeys,
  deleteAPIKey,
  showAPIKey,
  getStoreInfo,
  updateStoreInfo, createWithdrawalRequisites, getWithdrawalsRequisites, deleteWithdrawalRequisites,
  createWebhook,
  deleteWebhook,
  getDocument,
  updateStore,
  getStore,
  getIPWL,
  updateIPWL,
} from './codecs.stores';
import {
  getTFA,
  activateTFA,
  turnOnTFA,
  turnOffTFA,
  getProfile,
  updateProfile, updateEmail, confirmUpdateEmailCode
} from "./codecs.users";
import {createInvoice, getInvoices, getInvoice, makePaidInvoice, deactivateInvoice, calcFee} from "./codecs.invoices";
import {createWithdrawal, declineWithdrawal, getWithdrawals} from "./codecs.withdrawals";
import {getPayments, exportPayments} from "./codecs.wallet";

const handlers = [
  logIn,
  registration,
  forgotPassword,
  updateForgottenPassword,
  confirmEmailCode,
  resendConfirmationCode,
  updatePassword,
  getTestnetToken,
  signInTestnet,
  OAuthlogIn,
  getTFA,
  activateTFA,
  turnOnTFA,
  turnOffTFA,
  getProfile,
  updateProfile,
  updateEmail,
  confirmUpdateEmailCode,
  createStore,
  getStores,
  createAPIKey,
  getAPIKeys,
  deleteAPIKey,
  createWebhook,
  deleteWebhook,
  showAPIKey,
  getStoreInfo,
  updateStoreInfo,
  createWithdrawalRequisites,
  getWithdrawalsRequisites,
  deleteWithdrawalRequisites,
  createInvoice,
  getInvoices,
  getInvoice,
  makePaidInvoice,
  deactivateInvoice,
  calcFee,
  createWithdrawal,
  getWithdrawals,
  declineWithdrawal,
  getPayments,
  exportPayments,
  getDocument,
  updateStore,
  getStore,
  getIPWL,
  updateIPWL,
  confirmLogIn,
] as const;

const handlersMap: Map<ApiHandlerTypes, ApiHandlers> = new Map();
for (const h of handlers) {
  handlersMap.set(h.type, h);
}

type DiscriminateUnion<T, K extends keyof T, V extends T[K]> = T extends Record<K, V> ? T : never;

type ApiHandlers = typeof handlers[number];
export type ApiHandlerTypes = ApiHandlers['type'];
export type ApiHandler<Type extends ApiHandlerTypes = ApiHandlerTypes> = DiscriminateUnion<
ApiHandlers,
'type',
Type
>;

export type ApiHandlerParams<Type extends ApiHandlerTypes> = Parameters<
ApiHandler<Type>['prepare']
>[0] extends {}
  ? Parameters<ApiHandler<Type>['prepare']>[0]
  : undefined;
export type ApiHandlerResponse<Type extends ApiHandlerTypes> = ReturnType<
ApiHandler<Type>['decode']
>;

export function getHandler<Type extends ApiHandlerTypes>(type: Type): ApiHandler<Type> {
  return handlersMap.get(type) as ApiHandler<Type>;
}
