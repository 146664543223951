import CssBaseline from '@mui/material/CssBaseline';
import { StylesProvider } from '@mui/styles';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastListener } from './contexts/ToastsContext'

import { GlobalStyle } from './App.Styles';
import { PATHS } from './const/paths.constants';
import GuestRoutes from './routes/guestRoutes';
import PrivateRoutes from './routes/privateRoutes';
import Login from './pages/Auth/Login';
import ConfirmLogin from './pages/Auth/Login/Confirm';
import Registration from './pages/Auth/Registration';
import ConfirmEmail from './pages/Auth/ConfirmEmail';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ConfirmNewPassword from './pages/Auth/ConfirmNewPassword';
import Home from './pages/Home/Home';
import Withdraw from './pages/Home/Withdraw';
import Payments from './pages/Payments/Payments';
import Invoices from './pages/Invoices/Invoices';
import CreateInvoice from './pages/Invoices/Create';
import InvoiceDetails from "./pages/Invoices/Details";
import Developers from "./pages/Developers/Developers";
import Settings from "./pages/Settings/Settings";
import ConfirmationEmail from './pages/Settings/ConfirmationEmail';
import StoreSettings from "./pages/StoreSettings/StoreSettings";
import OauthPassword from './pages/Settings/OauthPassword';
import Terms from './pages/Statics/Terms';
import Privacy from "./pages/Statics/Privacy";

function App() {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <CssBaseline />
      <Routes>
        <Route
          path="*"
          element={<Navigate to={PATHS.NEW_DASHBOARD} replace />}
        />

        <Route
          path={PATHS.NEW_DASHBOARD}
          element={
            <PrivateRoutes title="navBar.dashboard">
              <Home />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.DASHBOARD}
          element={
            <PrivateRoutes title="navBar.dashboard">
              <Home />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.WITHDRAW}
          element={
            <PrivateRoutes title="navBar.withdraw">
              <Withdraw />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.PAYMENTS}
          element={
            <PrivateRoutes title="navBar.payments">
              <Payments />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.INVOICES}
          element={
            <PrivateRoutes title="navBar.invoices">
              <Invoices />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.CREATE_INVOICE}
          element={
            <PrivateRoutes title="navBar.create_invoice">
              <CreateInvoice />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.INVOICE_DETAILS}
          element={
            <PrivateRoutes title="navBar.invoice_details">
              <InvoiceDetails />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.DEVELOPERS}
          element={
            <PrivateRoutes title="navBar.developers">
              <Developers />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.STORE_SETTINGS}
          element={
            <PrivateRoutes title="navBar.store_settings">
              <StoreSettings />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.SETTINGS}
          element={
            <PrivateRoutes title="navBar.settings">
              <Settings />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.CONFIRM_EMAIL}
          element={
            <PrivateRoutes title="Confirm email">
              <ConfirmationEmail />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.OAUTH_PASSWORD}
          element={
            <PrivateRoutes title="Recover password">
              <OauthPassword />
            </PrivateRoutes>
          }
        />

        <Route
          path={PATHS.LOGIN}
          element={
            <GuestRoutes title="Login">
              <Login />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.CONFIRM_LOGIN}
          element={
            <GuestRoutes title="Confirm Login">
              <ConfirmLogin />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.REGISTRATION}
          element={
            <GuestRoutes title="Registration">
              <Registration />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.CONFIRMATION}
          element={
            <GuestRoutes title="Confirm email">
              <ConfirmEmail />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.FORGOT_PASSWORD}
          element={
            <GuestRoutes title="Forgot password">
              <ForgotPassword />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.CONFIRMATION_FORGOT_PASSWORD}
          element={
            <GuestRoutes title="Confirm Forgot password">
              <ConfirmNewPassword />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.TERMS_OF_USE}
          element={
            <GuestRoutes title="Cryptoprocessing service usage agreement">
              <Terms />
            </GuestRoutes>
          }
        />

        <Route
          path={PATHS.PRIVACY_POLICE}
          element={
            <GuestRoutes title="Privacy Policy">
              <Privacy />
            </GuestRoutes>
          }
        />

      </Routes>
      <ToastListener />
    </StylesProvider>
  );
}

export default App;
