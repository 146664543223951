import { FormControlLabel, Checkbox } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';

import { validateEmail, validatePassword } from '../../../common/utils/validators';
import {
  Button,
  Title,
  Input,
  Loader,
  Logo,
  SelectAutocomplete,
} from '../../../elements';
import { RegistrationStyles } from './Registration.Styles';
import {RegistrationParams} from "../../../api";
import {Link, useNavigate} from "react-router-dom";
import {PATHS} from "../../../const/paths.constants";
import { AppStateType } from '../../../store';
import types from '../../../store/actionTypes';
import { selectErrorByKey, selectLoadingByKey } from '../../../store/loadingsErrors/selectors';
import { registration, clear } from '../../../store/user/actions';
import { COUNTRIES, CountryWithLangs } from "../../../const/countries.constants";
import {UserReducerState} from "../../../store/user/reducers";
import useToast from "../../../hooks/useToast";
import {getGoogleUrl} from "../../../common/utils/getGoogleUrl";
import {getGitHubUrl} from "../../../common/utils/getGithubUrl";

export interface RegistrationProps {
  user: UserReducerState;
  registration: (payload: RegistrationParams | any) => void;
  clear: () => void;
  loading: boolean;
  error: string | null;
}

const Registration: React.FC<RegistrationProps> = (props: RegistrationProps) => {
  const { clear, user, registration, loading } = props;
  const { toastError, toastSuccess } = useToast();
  const navigate = useNavigate();

  const countriesList = COUNTRIES.map((country: CountryWithLangs) => {
    return {
      value: country.code.toLowerCase(),
      text: country.en
    }
  })

  const [values, setValues] = useState<{ [key: string]: string }>({
    country: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    repassword: ''
  });
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({
    password: false,
    repassword: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [termsRegistration, setTermsRegistration] = useState<boolean>(false);
  const [focusedField, setFocusedField] = useState<string>('');

  useEffect(() => {

    if (user.answer?.error) {
      if (user.answer.error.error) {
        toastError(String(user.answer.error.error));
      }
      const newErrors: RegistrationParams = {
        country: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repassword: ''
      };
      Object.keys(user.answer.error).forEach((key: string) => {
        newErrors[key] = user.answer.error[key];
      });

      setErrors(newErrors);

      clear();
    }

    if (user.answer?.success) {
      toastSuccess(user.answer?.success);
      navigate(`${PATHS.CONFIRMATION}?confirmation_email=${values.email}`);
      clear();
    }
  }, [user.answer, toastError, toastSuccess, setErrors, navigate, clear]);

  let getFormErrors: (data: { [p: string]: string }) => RegistrationParams;
  getFormErrors = (data: { [key: string]: string }) => {
    const {country, first_name, last_name, email, password, repassword} = data;
    const newErrors: RegistrationParams = {
      country: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      repassword: '',
      terms: ''
    };

    if (!country) newErrors.country = 'Select country';
    if (!first_name) newErrors.first_name = 'Enter first name';
    if (!last_name) newErrors.last_name = 'Enter last name';
    if (!email) newErrors.email = 'Enter email';
    if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email';
    if (!password) newErrors.password = 'Enter password';
    if (password && !validatePassword(password)) newErrors.password = 'Please enter a valid password';
    if (!repassword) newErrors.repassword = 'Enter password again';
    if (repassword !== password) newErrors.repassword = 'Password must match';
    if (!termsRegistration) newErrors.terms = 'Please confirm the terms';

    return newErrors;
  };

  const checkErrors = (data: { [key: string]: string }) => {
    for (const error in data) {
      if (data[error]) return true;
    }
    return false;
  };

  const onChange = (field: string, value: string) => {
    setValues(prev => ({
      ...prev,
      [field]: value,
    }));

    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: '',
      });
    }

    if (!value && Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      newValues[field] = value;
      const newErrors: RegistrationParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onBlur = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const newValues = cloneDeep(values);
      const newErrors: RegistrationParams = getFormErrors(newValues);

      setErrors({
        ...errors,
        [field]: newErrors[field],
      });
    }
  };

  const onToggleShow = (field: string) => {
    if (Object.prototype.hasOwnProperty.call(showInput, field)) {
      setShowInput({
        ...showInput,
        [field]: !showInput[field],
      });
    }
  };

  const onChangeTermsRegistration = (value: boolean) => {
    setTermsRegistration(value);
    if (Object.prototype.hasOwnProperty.call(errors, 'terms')) {
      const showError = !value;
      setErrors({
        ...errors,
        terms: showError ? 'Statics is not chacked' : '',
      });
    }
  };

  const onSubmit = useCallback(
    (e: React.ChangeEvent<any>) => {
      e.preventDefault();
      const newErrors: RegistrationParams = getFormErrors(values);
      setErrors(newErrors);

      const data: RegistrationParams = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.toLowerCase(),
        password: values.password,
      };
      if (!checkErrors(newErrors)) {
        registration(data);
      } else {
        const errArr = Object.keys(newErrors).filter((name) => !!newErrors[name]);

        if (!!errArr.length) {
          setFocusedField(errArr[0]);
        }
      }
    },
    [values, getFormErrors, registration]
  );

  return (
    <RegistrationStyles className='registration'>
      <div className="registration-container">
        <div className="grid-container registration-grid">
          <div className="registration-top">
            <Logo className="registration-logo" to={PATHS.NEW_DASHBOARD}>
              <img src="/img/main/logo.svg" alt="cp" />
            </Logo>
          </div>
          <div className="registration-center">
            <div className="registration-box">

              <Title className='registration-title'>
                Sign up
              </Title>
              <form onSubmit={onSubmit}>
                <SelectAutocomplete
                  className="registration-select"
                  name="country"
                  selectedValue={values.country}
                  placeholder="Country"
                  error={errors.country}
                  list={countriesList}
                  fullWidth
                  onChange={onChange}
                />
                <Input
                  className='registration-input'
                  type="text"
                  name="first_name"
                  value={values.first_name}
                  placeholder="Enter your first name"
                  label="First name"
                  error={errors.first_name}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={focusedField === 'first_name'}
                  setOnFocus={setFocusedField}
                />
                <Input
                  className='registration-input'
                  type="text"
                  name="last_name"
                  value={values.last_name}
                  placeholder="Enter your last name"
                  label="Last name"
                  error={errors.last_name}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={focusedField === 'last_name'}
                  setOnFocus={setFocusedField}
                />
                <Input
                  className='registration-input'
                  type="email"
                  name="email"
                  value={values.email}
                  placeholder="Enter your email"
                  label="Email"
                  error={errors.email}
                  onChange={onChange}
                  onBlur={onBlur}
                  onFocus={focusedField === 'email'}
                  setOnFocus={setFocusedField}
                />
                <Input
                  className='registration-input'
                  type="password"
                  name="password"
                  value={values.password}
                  placeholder="Create a password"
                  label="Password"
                  error={errors.password}
                  onChange={onChange}
                  onBlur={onBlur}
                  show={showInput.password}
                  onShow={onToggleShow}
                  onFocus={focusedField === 'password'}
                  setOnFocus={setFocusedField}
                />
                <span className='registration-input__undertext'>Must be at least 8 characters.</span>

                <Input
                  className='registration-input'
                  type="password"
                  name="repassword"
                  value={values.repassword}
                  placeholder="Repeat a password"
                  label="Repeat Password"
                  error={errors.repassword}
                  onChange={onChange}
                  onBlur={onBlur}
                  show={showInput.repassword}
                  onShow={onToggleShow}
                  onFocus={focusedField === 'repassword'}
                  setOnFocus={setFocusedField}
                />

                <FormControlLabel
                  className={`registration-checkbox ${errors.terms ? '-error' : ''}`}
                  control={
                    <Checkbox
                      checked={termsRegistration}
                      aria-describedby="termsRegistration-text"
                      onChange={(e: React.ChangeEvent<any>) =>
                        onChangeTermsRegistration(e.target.checked)}
                    />
                  }
                  label={
                    <>
                      I agree to the
                      {' '}
                      <a
                        href={PATHS.TERMS_OF_USE}
                        tabIndex={-1}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>
                      {' '}
                      and
                      {' '}
                      <a
                        href={PATHS.PRIVACY_POLICE}
                        tabIndex={-1}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Police
                      </a>
                    </>
                  }
                />

                <Button
                  className="registration-btn"
                  type="submit"
                  disabled={loading}
                >
                  Create account
                  {loading ? <Loader /> : null}
                </Button>
              </form>

              <div className="registration-or">
                <span>OR</span>
              </div>

              <div className="registration-oauth">
                <Button
                  className="registration-btn -google"
                  as={Link}
                  to={getGoogleUrl()}
                  disabled={loading}
                >
                  Continue with Google
                </Button>

                <Button
                  className="registration-btn -github"
                  as={Link}
                  to={getGitHubUrl()}
                  disabled={loading}
                >
                  Continue with GitHub
                </Button>
              </div>

              <div className="registration-entrance">
                <p>Already have an account?</p>
                <Link to={PATHS.LOGIN}>Sign in</Link>
              </div>
            </div>
          </div>
          <div className="registration-bottom">
            <div className="registration-bottom__copyright_wrap">
              <span className="registration-bottom__copyright">© Cryptoprocessing</span>
            </div>
            <div className="registration-bottom__link_wrap">
              <Link tabIndex={-1} className="registration-bottom__link" to={PATHS.PRIVACY_POLICE} target="_blank">Privacy</Link>
              <span className="registration-bottom__link"> & </span>
              <Link tabIndex={-1} className="registration-bottom__link" to={PATHS.TERMS_OF_USE} target="_blank">terms</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="registration-image">
        <div className="registration-image__container">
          <div className="registration-image__box">
            <div className="registration-image__star">
              <img src="/img/auth/star.svg" alt="Tier" />
            </div>
            <div className="registration-image__title">Start accepting crypto payments easily and securely.</div>
            <div className="registration-image__text">Create a free account and get full access to all features for 30-days. No credit card needed. Get started in 2 minutes.</div>
            <div className="registration-image__avatars">
              <div className="registration-image__avatar">
                <img
                  className="registration-image__avatar_img"
                  srcSet="/img/auth/ava1.png, /img/auth/ava1@2x.png 2x"
                  src="/img/auth/ava1.png"
                  alt="Tier"
                />
                <img
                  className="registration-image__avatar_img"
                  srcSet="/img/auth/ava2.png, /img/auth/ava2@2x.png 2x"
                  src="/img/auth/ava2.png"
                  alt="Tier"
                />
                <img
                  className="registration-image__avatar_img"
                  srcSet="/img/auth/ava3.png, /img/auth/ava3@2x.png 2x"
                  src="/img/auth/ava3.png"
                  alt="Tier"
                />
                <img
                  className="registration-image__avatar_img"
                  srcSet="/img/auth/ava4.png, /img/auth/ava4@2x.png 2x"
                  src="/img/auth/ava4.png"
                  alt="Tier"
                />
                <img
                  className="registration-image__avatar_img"
                  srcSet="/img/auth/ava5.png, /img/auth/ava5@2x.png 2x"
                  src="/img/auth/ava5.png"
                  alt="Tier"
                />
              </div>
              <p>Join 40,000+ users</p>
              <div className="registration-image__arrow">
                <img src="/img/auth/arrow.svg" alt="Tier" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RegistrationStyles>
  );
};

const mapState = (state: AppStateType) => {
  const { user } = state;
  return {
    user,
    loading: selectLoadingByKey(state, types.REGISTRATION_REQUEST),
    error: selectErrorByKey(state, types.REGISTRATION_REQUEST),
  };
};

export default connect(mapState, { registration, clear })(Registration);
