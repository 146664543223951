import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';

import API from '../../api/executor';
import { setLoading } from '../loadingsErrors/actions';
import { getStores as getStoresAction } from '../stores/actions';
import {
  RegistrationRequest,
  LoginRequest,
  ForgotPasswordRequest,
  ActivateTFARequest,
  TurnOffTFARequest,
  TurnOnTFARequest,
  ConfirmEmailRequest,
  ResendConfirmationRequest,
  UpdateProfileRequest,
  UpdatePasswordRequest,
  UpdateEmailRequest,
  ChangeUsingTestnet,
  UpdateForgottenPasswordRequest,
  ConfirmChangeEmailRequest,
  OAuthLoginRequest, ConfirmLoginRequest
} from './actionTypes';
import types from '../actionTypes';
import {STORAGE_KEYS} from "../../const/storage_keys.constants";
import {UserData} from "../../api";

// calls
const logInCall = (payload?: any) => API.call('logIn', payload);
const registrationCall = (payload?: any) => API.call('registration', payload);
const forgotPasswordCall = (payload?: any) => API.call('forgotPassword', payload);
const updateForgottenPasswordCall = (payload?: any) => API.call('updateForgottenPassword', payload);
const confirmEmailCall = (payload?: any) => API.call('confirmEmailCode', payload);
const resendConfirmationCall = (payload?: any) => API.call('resendConfirmationCode', payload);
const updatePasswordCall = (payload?: any) => API.call('updatePassword', payload);
const getTFACall = () => API.call('getTFA', undefined);
const activateTFACall = (payload?: any) => API.call('activateTFA', payload);
const turnOffTFACall = (payload?: any) => API.call('turnOffTFA', payload);
const turnOnTFACall = (payload?: any) => API.call('turnOnTFA', payload);
const getProfileCall = (payload?: any) => API.call('getProfile', payload);
const updateProfileCall = (payload?: any) => API.call('updateProfile', payload);
const updateEmailCall = (payload?: any) => API.call('updateEmail', payload);
const getTestnetTokenCall = (payload?: any) => API.call('getTestnetToken', payload);
const signInTestnetCall = (payload?: any) => API.call('signInTestnet', payload);
const confirmChangeEmailCall = (payload?: any) => API.call('confirmUpdateEmailCode', payload);
const OAuthLogInCall = (payload?: any) => API.call('OAuthlogIn', payload);
const confirmLogInCall = (payload?: any) => API.call('confirmLogIn', payload);


// call types
type Login = SagaReturnType<typeof logInCall>;
type GetTFA = SagaReturnType<typeof getTFACall>;
type GetProfile = SagaReturnType<typeof getProfileCall>;
type UpdateEmail = SagaReturnType<typeof updateEmailCall>;
type GetTestnetToken = SagaReturnType<typeof getTestnetTokenCall>;
type SignInTestnet = SagaReturnType<typeof signInTestnetCall>;
type OAuthLogin = SagaReturnType<typeof OAuthLogInCall>;
type ConfirmLogIn = SagaReturnType<typeof confirmLogInCall>;


function* login({ payload }: LoginRequest) {
  yield put(setLoading(types.LOGIN_REQUEST, true));
  try {
    const res: Login = yield call(() => logInCall(payload));
    const token = {
      accessToken: res.auth_token,
      ownerUid: res.auth_owner_uid,
    }

    const user: UserData = {
      first_name: res.first_name,
      last_name: res.last_name,
      email: res.email,
      country: res.country || '',
      tfa_enabled: !!res.tfa_enabled,
    }

    localStorage.setItem(STORAGE_KEYS.AUTH, JSON.stringify(token));
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
    yield all([
      put({ type: types.LOGIN_SUCCESS, payload: res}),
      put(setLoading(types.LOGIN_REQUEST, false))
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.LOGIN_REQUEST, false)),
        put({
          type: types.LOGIN_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* confirmLogin({ payload }: ConfirmLoginRequest) {
  const user = payload?.user || {};
  yield put(setLoading(types.CONFIRM_LOGIN_REQUEST, true));
  try {
    const res: ConfirmLogIn = yield call(() => confirmLogInCall(payload));

    user.tfa_enabled = !res.tfa_verified;

    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
    yield all([
      put({ type: types.CONFIRM_LOGIN_SUCCESS, payload: user}),
      put(setLoading(types.CONFIRM_LOGIN_REQUEST, false))
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.CONFIRM_LOGIN_REQUEST, false)),
        put({
          type: types.CONFIRM_LOGIN_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

function* registration({ payload }: RegistrationRequest) {
  yield put(setLoading(types.REGISTRATION_REQUEST, true));
  try {
    yield call(() => registrationCall(payload));

    yield all([
      put({ type: types.REGISTRATION_SUCCESS, payload: {
        success: 'You have successfully registered'}
      }),
      put(setLoading(types.REGISTRATION_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.REGISTRATION_REQUEST, false)),
      put({
        type: types.REGISTRATION_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* forgotPassword({ payload }: ForgotPasswordRequest) {
  yield put(setLoading(types.FORGOT_PASSWORD_REQUEST, true));
  try {
    yield call(() => forgotPasswordCall(payload));

    yield all([
      put({ type: types.FORGOT_PASSWORD_SUCCESS, payload: {
        success: 'A recovery link has been sent to your email'}
      }),
      put(setLoading(types.FORGOT_PASSWORD_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.FORGOT_PASSWORD_REQUEST, false)),
      put({
        type: types.FORGOT_PASSWORD_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateForgottenPassword({ payload }: UpdateForgottenPasswordRequest) {
  yield put(setLoading(types.UPDATE_FORGOTTEN_PASSWORD_REQUEST, true));
  try {
    yield call(() => updateForgottenPasswordCall(payload));

    yield all([
      put({ type: types.UPDATE_FORGOTTEN_PASSWORD_SUCCESS, payload: {
          success: 'Your password successfully changed'}
      }),
      put(setLoading(types.UPDATE_FORGOTTEN_PASSWORD_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_FORGOTTEN_PASSWORD_REQUEST, false)),
      put({
        type: types.UPDATE_FORGOTTEN_PASSWORD_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* confirmEmail({ payload }: ConfirmEmailRequest) {
  yield put(setLoading(types.CONFIRM_EMAIL_REQUEST, true));
  try {
    yield call(() => confirmEmailCall(payload));

    yield all([
      put({ type: types.CONFIRM_EMAIL_SUCCESS, payload: {
          success: 'Your email has been successfully verified'}
      }),
      put(setLoading(types.CONFIRM_EMAIL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.CONFIRM_EMAIL_REQUEST, false)),
      put({
        type: types.CONFIRM_EMAIL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* resendConfirmation({ payload }: ResendConfirmationRequest) {
  yield put(setLoading(types.RESEND_CONFIRMATION_REQUEST, true));
  try {
    yield call(() => resendConfirmationCall(payload));

    yield all([
      put({ type: types.RESEND_CONFIRMATION_SUCCESS, payload: {
          success: 'An letter with a confirmation code has been sent to your email'}
      }),
      put(setLoading(types.RESEND_CONFIRMATION_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.RESEND_CONFIRMATION_REQUEST, false)),
      put({
        type: types.RESEND_CONFIRMATION_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updatePassword({ payload }: UpdatePasswordRequest) {
  yield put(setLoading(types.UPDATE_PASSWORD_REQUEST, true));
  try {
    yield call(() => updatePasswordCall(payload));

    yield all([
      put({ type: types.UPDATE_PASSWORD_SUCCESS, payload: {
        success: 'Your password successfully updated'}
      }),
      put(setLoading(types.UPDATE_PASSWORD_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_PASSWORD_REQUEST, false)),
      put({
        type: types.UPDATE_PASSWORD_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getTFA() {
  yield put(setLoading(types.GET_TFA_REQUEST, true));
  try {
    const res: GetTFA = yield call(() => getTFACall());

    yield all([
      put({ type: types.GET_TFA_SUCCESS, payload: res }),
      put(setLoading(types.GET_TFA_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_TFA_REQUEST, false)),
      put({
        type: types.GET_TFA_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* activateTFA({ payload }: ActivateTFARequest) {
  yield put(setLoading(types.ACTIVATE_TFA_REQUEST, true));
  try {
    yield call(() => activateTFACall(payload));

    yield all([
      put({ type: types.ACTIVATE_TFA_SUCCESS, payload: {
          success: 'Two-factor authentication successfully activated'}
      }),
      put(setLoading(types.ACTIVATE_TFA_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.ACTIVATE_TFA_REQUEST, false)),
      put({
        type: types.ACTIVATE_TFA_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* turnOffTFA({ payload }: TurnOffTFARequest) {
  yield put(setLoading(types.TURN_OFF_TFA_REQUEST, true));
  try {
    yield call(() => turnOffTFACall(payload));

    yield all([
      put({ type: types.TURN_OFF_TFA_SUCCESS, payload: {
        success: 'Two-factor authentication successfully turned off'}
      }),
      put(setLoading(types.TURN_OFF_TFA_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.TURN_OFF_TFA_REQUEST, false)),
      put({
        type: types.TURN_OFF_TFA_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* turnOnTFA({ payload }: TurnOnTFARequest) {
  yield put(setLoading(types.TURN_ON_TFA_REQUEST, true));
  try {
    yield call(() => turnOnTFACall(payload));

    yield all([
      put({ type: types.TURN_ON_TFA_SUCCESS, payload: {
        success: 'Two-factor authentication successfully turned on'}
      }),
      put(setLoading(types.TURN_ON_TFA_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.TURN_ON_TFA_REQUEST, false)),
      put({
        type: types.TURN_ON_TFA_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* getProfile() {
  yield put(setLoading(types.GET_PROFILE_REQUEST, true));
  try {
    const res: GetProfile = yield call(() => getProfileCall());

    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(res));

    yield all([
      put({ type: types.GET_PROFILE_SUCCESS, payload: res }),
      put(setLoading(types.GET_PROFILE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.GET_PROFILE_REQUEST, false)),
      put({
        type: types.GET_PROFILE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateProfile({ payload }: UpdateProfileRequest) {
  yield put(setLoading(types.UPDATE_PROFILE_REQUEST, true));
  const profile: UserData = payload;
  try {
    yield call(() => updateProfileCall(payload));

    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(profile));

    yield all([
      put({ type: types.UPDATE_PROFILE_SUCCESS, payload: {
        profile,
        answer: {
          success: 'Your profile successfully updated'}
        }
      }),
      put(setLoading(types.UPDATE_PROFILE_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_PROFILE_REQUEST, false)),
      put({
        type: types.UPDATE_PROFILE_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* updateEmail({ payload }: UpdateEmailRequest) {
  yield put(setLoading(types.UPDATE_EMAIL_REQUEST, true));
  try {
    const res: UpdateEmail = yield call(() => updateEmailCall(payload));

    yield all([
      put({ type: types.UPDATE_EMAIL_SUCCESS, payload: {
        profile: res,
        answer: {
          success: 'Please confirm your new email'}
        }
      }),
      put(setLoading(types.UPDATE_EMAIL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.UPDATE_EMAIL_REQUEST, false)),
      put({
        type: types.UPDATE_EMAIL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* logout() {
  localStorage.removeItem(STORAGE_KEYS.AUTH);
  localStorage.removeItem(STORAGE_KEYS.ACTIVE_STORE);
  localStorage.removeItem(STORAGE_KEYS.ACTIVE_TESTNET_STORE);
  localStorage.removeItem(STORAGE_KEYS.USER);
  localStorage.removeItem(STORAGE_KEYS.USE_TESTNET);
  localStorage.removeItem(STORAGE_KEYS.TESTNET_AUTH);
  yield all([
    put({ type: types.LOGOUT_SUCCESS }),
    put(setLoading(types.LOGOUT_REQUEST, false))
  ]);
}

function* changeUsingTestnet({ payload }: ChangeUsingTestnet) {
  yield put(setLoading(types.CHANGE_USING_TESTNET, true));
  try {
    const testnet = payload;
    const testnet_auth = JSON.parse(localStorage.getItem(STORAGE_KEYS.TESTNET_AUTH) || '{}');

    if (testnet && testnet_auth.accessToken) {
      yield all([
        put({
          type: types.CHANGE_USING_TESTNET_SUCCESS,
          payload: {
            testnet,
            answer: {
              success: `You have successfully changed the network to ${testnet ? 'testnet' : 'mainnet'}`
            }
          }
        }),
        put(setLoading(types.CHANGE_USING_TESTNET, false)),
        put(getStoresAction())
      ]);
    }

    if (!testnet) {
      const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.USER) || '{}');

      yield all([
        put({
          type: types.CHANGE_USING_TESTNET_SUCCESS,
          payload: {
            testnet,
            answer: {
              success: `You have successfully changed the network to ${testnet ? 'testnet' : 'mainnet'}`
            }
          }
        }),
        put(setLoading(types.CHANGE_USING_TESTNET, false)),
        put(getStoresAction())
      ]);
    }

    if (testnet && !testnet_auth.accessToken) {
      const auth = JSON.parse(localStorage.getItem(STORAGE_KEYS.AUTH) || '{}');
      const testToken: GetTestnetToken = yield call(() => getTestnetTokenCall({uid: auth.ownerUid}));
      localStorage.setItem(STORAGE_KEYS.USE_TESTNET, 'true');
      const res: SignInTestnet = yield call(() => signInTestnetCall({...testToken, oauth_provider: 'cryptoprocessing'}));

      const token = {
        accessToken: res.auth_token,
        ownerUid: res.auth_owner_uid,
      }

      localStorage.setItem(STORAGE_KEYS.TESTNET_AUTH, JSON.stringify(token));
      yield all([
        put({
          type: types.CHANGE_USING_TESTNET_SUCCESS,
          payload: {
            testnet,
            answer: {
              success: `You have successfully changed the network to ${testnet ? 'testnet' : 'mainnet'}`
            }
          }
        }),
        put(setLoading(types.CHANGE_USING_TESTNET, false)),
        put(getStoresAction())
      ]);
    }
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put({
          type: types.CHANGE_USING_TESTNET_FAILURE,
          payload: {error: error},
        }),
        put(setLoading(types.CHANGE_USING_TESTNET, false)),
      ]);
    }
  }
}

function* confirmChangeEmail({ payload }: ConfirmChangeEmailRequest) {
  yield put(setLoading(types.CONFIRM_CHANGE_EMAIL_REQUEST, true));
  try {
    yield call(() => confirmChangeEmailCall(payload));

    yield all([
      put({ type: types.CONFIRM_CHANGE_EMAIL_SUCCESS, payload: {
          success: 'Your email has been successfully verified'}
      }),
      put(setLoading(types.CONFIRM_CHANGE_EMAIL_REQUEST, false)),
    ]);
  } catch (e: any) {
    const error = e?.errObj ? e.errObj : {error: e.toString()};

    yield all([
      put(setLoading(types.CONFIRM_CHANGE_EMAIL_REQUEST, false)),
      put({
        type: types.CONFIRM_CHANGE_EMAIL_FAILURE,
        payload: {error: error},
      }),
    ]);
  }
}

function* oAuthLogin({ payload }: OAuthLoginRequest) {
  yield put(setLoading(types.OAUTH_LOGIN_REQUEST, true));
  try {
    const res: OAuthLogin = yield call(() => OAuthLogInCall(payload));
    const token = {
      accessToken: res.auth_token,
      ownerUid: res.auth_owner_uid,
    }

    const user: UserData = {
      first_name: res.first_name,
      last_name: res.last_name,
      email: res.email,
      country: res.country || '',
    }

    localStorage.setItem(STORAGE_KEYS.AUTH, JSON.stringify(token));
    localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(user));
    yield all([
      put({ type: types.OAUTH_LOGIN_SUCCESS, payload: res}),
      put(setLoading(types.OAUTH_LOGIN_REQUEST, false))
    ]);
  } catch (e: any) {
    if (e?.errObj) {
      const error = e?.errObj ? e.errObj : {error: e.toString()};

      yield all([
        put(setLoading(types.OAUTH_LOGIN_REQUEST, false)),
        put({
          type: types.OAUTH_LOGIN_FAILURE,
          payload: {error: error},
        }),
      ]);
    }
  }
}

export default function* usersSagas() {
  yield takeLatest(types.LOGIN_REQUEST, login);
  yield takeLatest(types.REGISTRATION_REQUEST, registration);
  yield takeLatest(types.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(types.UPDATE_FORGOTTEN_PASSWORD_REQUEST, updateForgottenPassword);
  yield takeLatest(types.CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeLatest(types.RESEND_CONFIRMATION_REQUEST, resendConfirmation);
  yield takeLatest(types.UPDATE_PASSWORD_REQUEST, updatePassword);
  yield takeLatest(types.GET_TFA_REQUEST, getTFA);
  yield takeLatest(types.ACTIVATE_TFA_REQUEST, activateTFA);
  yield takeLatest(types.TURN_OFF_TFA_REQUEST, turnOffTFA);
  yield takeLatest(types.TURN_ON_TFA_REQUEST, turnOnTFA);
  yield takeLatest(types.GET_PROFILE_REQUEST, getProfile);
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(types.UPDATE_EMAIL_REQUEST, updateEmail);
  yield takeLatest(types.LOGOUT_REQUEST, logout);
  yield takeLatest(types.CHANGE_USING_TESTNET, changeUsingTestnet);
  yield takeLatest(types.CONFIRM_CHANGE_EMAIL_REQUEST, confirmChangeEmail);
  yield takeLatest(types.OAUTH_LOGIN_REQUEST, oAuthLogin);
  yield takeLatest(types.CONFIRM_LOGIN_REQUEST, confirmLogin);
}
