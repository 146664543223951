import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Container, MainContent, ExportPayments,} from '../../elements';
import {PaymentsContainer} from "./Payments.Styles";
import Transactions, {Transaction, TransactionRows, TransactionType} from "../../components/Transactions/Transactions";
import {AppStateType} from "../../store";
import {selectLoadingByKey} from "../../store/loadingsErrors/selectors";
import types from "../../store/actionTypes";
import {getPayments, setPaymentsLoading} from "../../store/wallet/actions";
import {clear} from "../../store/user/actions";
import {PaginatedPayments, WalletReducerState} from "../../store/wallet/reducers";
import {StoresReducerState} from "../../store/stores/reducers";
import {DEFAULT_PAGE_LIMIT, LOADING_TYPES} from "../../const/app.constants";
import {ExportPaymentsParams, GetPaymentsParams, Payment} from "../../api";
import useToast from "../../hooks/useToast";
import {useSearchParams} from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import {formatNumber} from "../../common/utils/formatters";
import {PATHS} from "../../const/paths.constants";
import {DateRange} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";
import API from "../../api/executor";
import {ApiHandlerResponse} from "../../api/calls";

interface Props {
  stores: StoresReducerState;
  wallet: WalletReducerState;
  loading: boolean;
  getPayments: (payload: GetPaymentsParams) => void;
  setPaymentsLoading: (payload: GetPaymentsParams) => void;
  clear: () => void;
}

const Payments: React.FC<Props> = (props: Props) => {
  const {stores, wallet, loading, getPayments, clear, setPaymentsLoading} = props;
  const { toastError } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryLimit = searchParams.get('limit') || 0;
  const queryPage = searchParams.get('page') || 0;
  const queryStatus = searchParams.get('status') || null;
  const queryFromDay = searchParams.get('from_day') || null;
  const queryToDay = searchParams.get('to_day') || null;
  const querySortBy = searchParams.get('sort_by') || null;
  const queryDirection = searchParams.get('direction') || null;

  const [activeStore, setActiveStore] = useState<string | null>(null);
  const [paginatedPayments, setPaginatedPayments] = useState<PaginatedPayments | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [update, setUpdate] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<GetPaymentsParams>({
    status: queryStatus || '',
    from_day: queryFromDay || '',
    to_day: queryToDay || '',
    limit: queryLimit || '',
    page: queryPage || '',
    sort_by: querySortBy || '',
    direction: queryDirection || ''
  });
  const [dataRange, setDataRange] = useState<DateRange<Dayjs>>([dayjs((new Date())).subtract(3, 'month'), dayjs((new Date()))]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(!(wallet.payments[stores.activeStore || '']?.loaded === LOADING_TYPES.LOADED));

  const getDate = (date: string | undefined): number => {
    const newDate: Date = date ? new Date(date) : new Date()
    return Math.floor(newDate.getTime() / 1000);
  };

  const createTransactions = (payment: Payment[]) => {
    const newTransactions: Transaction[] = payment.map((p: Payment): Transaction => {
      return {
        id: p.id || '',
        number: p.tx_hash ? p.tx_hash : '...',
        date: getDate(p.created_at),
        customer: '-',
        status: p.tx_hash ? TransactionType.PAID : TransactionType.UNPAID,
        paid: `${formatNumber(p.amount_paid, 0, 4) || '-'} ${p.payment_currency || ''}`,
        deposited: `${Number(p.amount_usd) > 0 ? formatNumber(p.amount_usd, 0, 2) : formatNumber(0, 0, 2)} USD`,
        payment_currency: p.payment_currency,
        invoice_id: p.invoice_id || undefined
      }
    });
    setTransactions(newTransactions)
  };

  const getSortBy = (sort: string) => {
    let newSort = sort;
    switch (sort) {
      case 'number':
        newSort = 'address';
        break;
      case 'amount':
        newSort = 'amount_usd';
        break;
      case 'paid':
        newSort = 'amount_paid';
        break;
      default:
        break;
    }
    return newSort
  }

  const updateSearchParams = (params: GetPaymentsParams) => {
    const queryObj: any = {};
    const newFilterParams = cloneDeep(filterParams);

    queryObj.page = `${params.page}`;
    newFilterParams.page = `${params.page}`;
    queryObj.limit = `${params.limit}`;
    newFilterParams.limit = `${params.limit}`;
    queryObj.status = `${params.status}`;
    newFilterParams.status = `${params.status}`;
    queryObj.from_day = `${params.from_day}`;
    newFilterParams.from_day = `${params.from_day}`;
    queryObj.to_day = `${params.to_day}`;
    newFilterParams.to_day = `${params.to_day}`;
    queryObj.sort_by = `${params.sort_by}`;
    newFilterParams.sort_by = `${params.sort_by}`;
    queryObj.direction = `${params.direction}`;
    newFilterParams.direction = `${params.direction}`;

    setFilterParams(newFilterParams);
    setSearchParams(queryObj);
  }

  useEffect(() => {
    if (stores.activeStore && stores.stores.loadedShop === LOADING_TYPES.LOADED) {
      if (!wallet.payments[stores.activeStore] || wallet.payments[stores.activeStore]?.loaded === LOADING_TYPES.NOT_LOADED ) {
        setActiveStore(stores.activeStore);
        getPayments({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        })
      }

      if (activeStore && (activeStore !== stores.activeStore)) {
        setActiveStore(stores.activeStore);
        setTransactions([]);
        setPaginatedPayments(null);
        getPayments({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        })
      }

      if (wallet.payments[stores.activeStore]?.loaded === LOADING_TYPES.LOADED && !transactionsLoaded) {
        setTransactionsLoaded(true);
        setPaymentsLoading({
          storeId: stores.activeStore,
          status: filterParams.status,
          from_day: filterParams.from_day,
          to_day: filterParams.to_day,
          page: filterParams.page || 1,
          limit: filterParams.limit || DEFAULT_PAGE_LIMIT,
          sort_by: getSortBy(filterParams.sort_by || '') || 'created_at',
          direction: filterParams.direction || 'desc'
        });
      }

      if (wallet.payments[stores.activeStore]?.loaded === LOADING_TYPES.LOADED && transactionsLoaded) {
        if (transactions.length === 0 && wallet.payments[stores.activeStore].list.length > 0) {
          const pagPayment = wallet.payments[stores.activeStore];
          createTransactions(pagPayment.list)
          setPaginatedPayments(pagPayment)
          updateSearchParams({
            limit: pagPayment.limit,
            page: pagPayment.page,
            status: pagPayment.status,
            from_day: pagPayment.from_day,
            to_day: pagPayment.to_day,
            sort_by: pagPayment.sort_by,
            direction: pagPayment.direction,
          })
        }

        if (
          update &&
          wallet.payments[stores.activeStore].status === filterParams.status &&
          wallet.payments[stores.activeStore].from_day === filterParams.from_day &&
          wallet.payments[stores.activeStore].to_day === filterParams.to_day &&
          wallet.payments[stores.activeStore].page === Number(filterParams.page) &&
          wallet.payments[stores.activeStore].limit === Number(filterParams.limit) &&
          wallet.payments[stores.activeStore].sort_by === filterParams.sort_by &&
          wallet.payments[stores.activeStore].direction === filterParams.direction
        ) {
          setUpdate(false);
          createTransactions(wallet.payments[stores.activeStore].list);
          setPaginatedPayments(wallet.payments[stores.activeStore]);
        }
      }
    }

    if (wallet.answer?.error?.error) {
      toastError(stores.answer.error?.error);
      clear();
    }
  }, [activeStore, transactionsLoaded, clear, createTransactions, filterParams, getPayments, stores.activeStore, stores.answer.error?.error, toastError, transactions.length, update, updateSearchParams, wallet, stores.stores.loadedShop, setPaymentsLoading]);

  const onUpdateFilterParams = (params: GetPaymentsParams) => {
    const newParams = cloneDeep(params);
    updateSearchParams(newParams);
    newParams.storeId = stores.activeStore || '';
    newParams.sort_by = getSortBy(newParams.sort_by);
    setUpdate(true);
    getPayments(newParams)
  }

  const setDates = async (data: DateRange<Dayjs>) => {
    setDataRange(data);
    const dataExport: ExportPaymentsParams = {
      storeId: activeStore || '',
      dateFrom: data[0] ? data[0]?.format('YYYY-MM-DD') : '',
      dateTo: data[1] ? data[1]?.format('YYYY-MM-DD') : '',
    };
    const exportPaymentsCall = (payload: ExportPaymentsParams) => API.call('exportPayments', payload);

    setExportLoading(true);
    await exportPaymentsCall(dataExport).then((documentBlob: ApiHandlerResponse<'exportPayments'>) => {
      const blobUrl = URL.createObjectURL(documentBlob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      setExportLoading(false);

      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );
      document.body.removeChild(link);
    });
  };

  return (
    <MainContent className="content-main">
      <Container>
        <PaymentsContainer className="payments">
          <div className="payments-head">
            <span className="payments-head__title">Payments</span>
            <div className="payments-head__btns">
              <ExportPayments
                dataRange={dataRange}
                setDataRange={setDates}
                loading={exportLoading}
              />
            </div>
          </div>
          <div className="payments-wrap">
            <Transactions
              items={transactions}
              loading={loading}
              from_day={queryFromDay || paginatedPayments?.from_day}
              to_day={queryToDay || paginatedPayments?.to_day}
              status={queryStatus || paginatedPayments?.status}
              sort_by={querySortBy || paginatedPayments?.sort_by}
              direction={queryDirection || paginatedPayments?.direction}
              paginationLimit={queryLimit || paginatedPayments?.limit}
              paginationPage={queryPage || paginatedPayments?.page}
              total_count={paginatedPayments?.total_count || paginatedPayments?.total_count || 0}
              updateFilterParams={onUpdateFilterParams}
              link={PATHS.INVOICE_DETAILS.replace(':store', `${stores.activeStore}`)}
              linkId={'invoice_id'}
              rows={[TransactionRows.TX, TransactionRows.DATE, TransactionRows.PAID, TransactionRows.DEPOSITED, TransactionRows.STATUS]}
            />
          </div>
        </PaymentsContainer>
      </Container>
    </MainContent>
  );
};

const mapStateToProps = (state: AppStateType) => {
  const { wallet, stores } = state;
  return {
    stores,
    wallet,
    loading: selectLoadingByKey(state, types.GET_PAYMENTS_REQUEST),
  };
};

export default connect(mapStateToProps, {getPayments, clear, setPaymentsLoading})(Payments);
